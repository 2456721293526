import {
    makeStyles, Backdrop, CssBaseline
} from '@material-ui/core';
// Models and Components
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import * as constant from '../../../constants/Constant';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { ITemplateField } from '../../../models/Placements/ITemplateField';
import { IClientCodeMap } from '../../../models/Placements/IClientCodeMapped';
import { IClientList } from '../../../models/Admin/ClientPlacements/IClientList';
import { IPlacementAccount } from '../../../models/Placements/IPlacementAccount';
import { useGlobalState } from '../../../store/GlobalStore';
import _ from 'lodash';
import { IDateFormatDetails } from '../../../models/Placements/IFixedDateFormat';
import Papa from 'papaparse';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';
import { IClientCodeExempted } from '../../../models/Placements/IPlacementRejectRules';
import { usePost } from '../../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
    buttons: {
        borderRadius: 20,
        fontSize: 12,
        fontWeight: 'bold',
        background: "#007FFF",
        color: "white",
        marginTop: "5px",
        float: 'left'
    },
    div: {
        margin: "0px 10px"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
}))

function DownloadRejectedAccounts(props) {
    let history = useHistory();
    const classes = useStyles();
    let location = useLocation();
    const { state } = useGlobalState();
    let clientCodeMappingList: IClientCodeMap[] = (props.location?.state?.clientCodeMappingList);
    let placementAccountList: IPlacementAccount[] = props.location.state.placementMappingAccountList;
    let placementAccountCSVData: string[][] = props.location?.state?.placementAccountCSVData;
    let finalDateFormat: IDateFormatDetails = props?.location?.state?.finalDateFormat;
    let mappedClientCodeValue: string = props.location.state.mappedClientCodeValue;
    let selectedHeaderList: string[] = props.location?.state?.selectedHeaderList;
    let filteredHeaderList: string[] = props.location?.state?.filteredHeaderList;
    let filteredCSVData: string[][] = props.location?.state?.filteredCSVData;
    let templateList: ITemplateField[] = props.location.state.templateList;
    let ruleRejectedAccounts: any[] = props?.location?.state?.PlacementRejectedAccounts;
    let placementDuplicateAccountsList: any[] = props?.location?.state?.placementDuplicateAccountsList;
    let mappedRequireFields: any[] = props?.location?.state?.mappedRequireFields;

    let clientList: IClientList[] = props.location.state.clientList;
    let mBalanceValue = (props.location?.state?.mBalanceValue);
    let ignoreRowNumber = props.location?.state?.ignoreRows;
    let feeValue = props.location?.state?.feeValue;
    const FeeObject = { field_name: 'fees', display_name: 'Fees (*)', data_type: 'number', is_nullable: false };
    const [futureVal, setFutureVal] = useState<boolean>(props.location?.state?.futureVal ? props.location?.state?.futureVal : false);
    const [csvUrl, setCSVUrl] = useState('');
    const [rejectedAccountList, setRejectedAccountList] = useState(0);
    let clientCode: string = props?.location?.state?.clientCode;
    let phaseNumber: number = props.location.state.phaseNumber;
    let isClientCodeExempt: IClientCodeExempted = props?.location?.state?.isClientCodeExempt;
    let IsAccountSubmited: boolean = props?.location?.state?.IsAccountSubmited;
    let fileName: string = props?.location?.state?.fileName;
    let uploadedFileName: string = props?.location?.state?.uploadedFileName;
    let FileId: number = props?.location?.state?.FileId;

    let filteredRejectedCSVData = [];

    const RemoveUnmappedColumns = (arr) => {
        let lastValidIndex = arr.length - 1;
        while (arr[lastValidIndex] === "-1" || arr[lastValidIndex] === undefined) {
            lastValidIndex -= 1;
        }
        return arr.slice(0, lastValidIndex + 1);
    };

    const getPhaseString = (phaseNum: number) => {
        switch (+phaseNum) {
            case 1:
                return "1STP";
            case 2:
                return "PREC";
            case 3:
                return "CONT";
            default:
                return "MULP";
        }
    }

    function convertListToCSV(list, columnsToRemove) {
        let mergedList = [...list];
        if (!ruleRejectedAccounts?.length && placementDuplicateAccountsList?.length) {
            mergedList = [...list, ...placementDuplicateAccountsList];
        }
        const originalColumnNames = Object.keys(mergedList[0]);
        const processedData = RemoveUnmappedColumns(selectedHeaderList);
        const alwaysIncludeColumn = 'Rejected Reason';
        const fullColumnSet = processedData?.map((column, index) =>
            column === '-1' || column === undefined ? `ignore_field_${index}` : column
        );
        const nonNullColumns = originalColumnNames.filter(columnName => mergedList.some(item => item[columnName] !== null) && !columnsToRemove.includes(columnName));
        const extendedColumns = [];
        for (let i = 0; i < fullColumnSet.length; i++) {
            if (fullColumnSet[i]?.startsWith('ignore_field')) {
                extendedColumns.push(fullColumnSet[i]);
            } else if (nonNullColumns.includes(fullColumnSet[i])) {
                extendedColumns.push(fullColumnSet[i]);
            }
        }
        if (originalColumnNames.includes(alwaysIncludeColumn) && !extendedColumns.includes(alwaysIncludeColumn)) {
            extendedColumns.push(alwaysIncludeColumn);
        }
        const filteredList = mergedList.map(item =>
            extendedColumns.reduce((obj, columnName) => {
                obj[columnName] = columnName.startsWith('ignore_field') ? null : item[columnName];
                return obj;
            }, {})
        );
        const csvData = Papa.unparse(filteredList);
        return csvData;
    }

    function mergeCSVResults(csvData1, csvData2) {
        const parseCSV = (csvData) => csvData ? Papa.parse(csvData, { header: true }).data : [];

        if (csvData1 || csvData2) {
            // Parse CSV data back to objects and combine
            const combinedData = [...parseCSV(csvData1), ...parseCSV(csvData2)];

            // This is to download the rejected accounts with account number and reject reason 
            const filteredData = combinedData.map(row => ({
                accountNumber: row['account_no'],
                rejectReason: row['Rejected Reason']
            }));

            // Convert combined data back to CSV and generate a Blob URL
            const mergedCSV1 = Papa.unparse(filteredData);
            const csvBlobFile = new Blob([mergedCSV1], { type: 'text/csv' });

            // Convert combined data back to CSV and generate a Blob URL
            const mergedCSV = Papa.unparse(combinedData);
            const csvBlob = new Blob([mergedCSV], { type: 'text/csv' });

            // If the Account is Submited need to save the Rejected File to Blob
            if (IsAccountSubmited) {
                const formData = new FormData();
                formData.append("userid", state?.userAccessContext?.id.toString());
                formData.append("ContainerName", "placement-rejected-files-prod");
                formData.append("clientCode", clientCode);
                formData.append("phaseCode", getPhaseString(phaseNumber));
                formData.append("OriginalFileName", FileId !== undefined ? fileName : uploadedFileName);
                formData.append("files", csvBlob, `Rejected_Accounts_Details-${clientCode}-${getPhaseString(phaseNumber)}-${moment(new Date()).format("MM-DD-YYYY")}.csv`);
                generateCSVFile(formData);
            }
            return URL.createObjectURL(csvBlobFile);
        }
        return null;
    }

    const generateCSVFile = (formData: FormData) => {
        (async () => {
            await usePost<any>("Placements/UploadRejectedFiles", formData);
        })()
    }

    function convertRuleRejectedListtoCSVFile(columnsToRemove) {
        let mergedList = [];
        if (!placementDuplicateAccountsList || placementDuplicateAccountsList?.length === 0) {
            mergedList = [...ruleRejectedAccounts];
        } else {
            mergedList = [...ruleRejectedAccounts, ...placementDuplicateAccountsList];
        }
        const originalColumnNames = Object.keys(mergedList[0]);
        const processedData = RemoveUnmappedColumns(selectedHeaderList);
        const alwaysIncludeColumn = 'Rejected Reason';
        const fullColumnSet = processedData?.map((column, index) =>
            column === '-1' || column === undefined ? `ignore_field_${index}` : column
        );
        const nonNullColumns = originalColumnNames?.filter(columnName => mergedList?.some(item => item[columnName] !== null) && !columnsToRemove.includes(columnName));
        const extendedColumns = [];
        for (let i = 0; i < fullColumnSet.length; i++) {
            if (fullColumnSet[i]?.startsWith('ignore_field')) {
                extendedColumns.push(fullColumnSet[i]);
            } else if (nonNullColumns.includes(fullColumnSet[i])) {
                extendedColumns.push(fullColumnSet[i]);
            }
        }
        if (originalColumnNames.includes(alwaysIncludeColumn) && !extendedColumns.includes(alwaysIncludeColumn)) {
            extendedColumns.push(alwaysIncludeColumn);
        }
        const filteredList = mergedList?.map(item =>
            extendedColumns.reduce((obj, columnName) => {
                obj[columnName] = columnName.startsWith('ignore_field') ? null : item[columnName];
                return obj;
            }, {})
        );
        const csvData = Papa.unparse(filteredList);
        return csvData;
    }

    useEffect(() => {
        // Calling the function to filter the rejected and success records from the List 
        handleDownloadRejectedRecords();
    }, [])

    // methods to get the list of rejected account from the csv file 
    const handleDownloadRejectedRecords = () => {
        let dateLength = 8;
        let zipLength = 4;
        let filteredHeaderList = _.cloneDeep(selectedHeaderList);
        let filteredCSVData = _.cloneDeep(placementAccountCSVData);
        let filteredPlacementAccountList = _.cloneDeep(placementAccountList);

        // Find the index of the account number column in filteredCSVData
        const accountNumberIndex = filteredHeaderList.indexOf('account_no');

        // Create a set of account numbers from placementDuplicateAccountsList
        const duplicateAccountNumbers = new Set(placementDuplicateAccountsList.map(record => record.account_no));

        // Filter out rows where the account number matches any in placementDuplicateAccountsList
        filteredCSVData = filteredCSVData.filter(row => !duplicateAccountNumbers.has(row[accountNumberIndex]));

        // Filter out rows from filteredPlacementAccountList where account_no matches any in placementDuplicateAccountsList
        filteredPlacementAccountList = filteredPlacementAccountList.filter(account => {
            return !duplicateAccountNumbers.has(account.account_no);
        });

        filteredCSVData.map((row, rowIndex) => {
            filteredCSVData[rowIndex] = filteredCSVData[rowIndex].filter((x, index) => filteredHeaderList[index] != undefined);
        });

        filteredHeaderList = filteredHeaderList.map(value => value === "-1" ? "Ignore_Field" : value);
        // Remove ignored rows from csv data
        if (ignoreRowNumber > 0) {
            filteredCSVData.splice(0, ignoreRowNumber);
            filteredPlacementAccountList.splice(0, ignoreRowNumber);
        }

        // Remove Invalid account number Rows
        if (filteredHeaderList.findIndex(x => x == "account_no") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => validateAccountNumber(filteredPlacementAccountList[rowIndex]?.account_no, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => validateAccountNumber(filteredPlacementAccountList[rowIndex]?.account_no, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid Phase Rows
        if (filteredHeaderList.findIndex(x => x == "phase") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => phase1(filteredPlacementAccountList[rowIndex]?.phase, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => phase1(filteredPlacementAccountList[rowIndex]?.phase, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid client code Rows
        if (filteredHeaderList.findIndex(x => x == "client_code") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => client_code(filteredPlacementAccountList[rowIndex]?.client_code, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => client_code(filteredPlacementAccountList[rowIndex]?.client_code, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid phone number Rows
        if (filteredHeaderList.findIndex(x => x == "phone_no") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => phone_no(filteredPlacementAccountList[rowIndex]?.phone_no, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => phone_no(filteredPlacementAccountList[rowIndex]?.phone_no, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid Codebtor Phone Number Rows
        if (filteredHeaderList.findIndex(x => x == "CoDebtor_Phone1") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => phone_no(filteredPlacementAccountList[rowIndex]?.CoDebtor_Phone1, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => phone_no(filteredPlacementAccountList[rowIndex]?.CoDebtor_Phone1, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid date Rows
        if (filteredHeaderList.findIndex(x => x == "delinquency_date") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex]?.delinquency_date, finalDateFormat?.delinquency_date, filteredPlacementAccountList[rowIndex], constant?.delinquency_date) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex]?.delinquency_date, finalDateFormat?.delinquency_date, filteredPlacementAccountList[rowIndex], constant?.delinquency_date) != false);
        }

        // Remove Invalid date Rows --Delinquency_Itemization_Date
        if (filteredHeaderList.findIndex(x => x == "Itemization_Date") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => ItemizationFutureDateValidation(filteredPlacementAccountList[rowIndex]?.Itemization_Date, finalDateFormat?.Itemization_Date, filteredPlacementAccountList[rowIndex], constant?.Itemization_Date) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => ItemizationFutureDateValidation(filteredPlacementAccountList[rowIndex]?.Itemization_Date, finalDateFormat?.Itemization_Date, filteredPlacementAccountList[rowIndex], constant?.Itemization_Date) != false);
        }

        // Remove Invalid date Rows --Itemization_Date
        if (filteredHeaderList.findIndex(x => x == "Delinquency_Itemization_Date") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex]?.delinquency_date, finalDateFormat?.delinquency_date, filteredPlacementAccountList[rowIndex], constant?.delinquency_date) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex]?.delinquency_date, finalDateFormat?.delinquency_date, filteredPlacementAccountList[rowIndex], constant?.delinquency_date) != false);
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex].Itemization_Date, finalDateFormat?.Itemization_Date, filteredPlacementAccountList[rowIndex], constant?.Itemization_Date) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex]?.Itemization_Date, finalDateFormat?.Itemization_Date, filteredPlacementAccountList[rowIndex], constant?.Itemization_Date) != false);
        }

        // Remove Invalid date Rows --last_payment_date
        if (filteredHeaderList.findIndex(x => x == "last_payment_date") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => DateValidation(filteredPlacementAccountList[rowIndex]?.last_payment_date, finalDateFormat?.last_payment_date, filteredPlacementAccountList[rowIndex], constant?.last_payment_date) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => DateValidation(filteredPlacementAccountList[rowIndex]?.last_payment_date, finalDateFormat?.last_payment_date, filteredPlacementAccountList[rowIndex], constant?.last_payment_date) != false);
        }

        // Remove Invalid date Rows --service_date
        if (filteredHeaderList.findIndex(x => x == "service_date") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => DateValidation(filteredPlacementAccountList[rowIndex]?.service_date, finalDateFormat?.service_date, filteredPlacementAccountList[rowIndex], constant?.service_date) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => DateValidation(filteredPlacementAccountList[rowIndex]?.service_date, finalDateFormat?.service_date, filteredPlacementAccountList[rowIndex], constant?.service_date) != false);
        }

        // Remove Invalid date Rows --birth_date
        if (filteredHeaderList.findIndex(x => x == "birth_date") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => DateVal(filteredPlacementAccountList[rowIndex]?.birth_date, finalDateFormat?.birth_date, filteredPlacementAccountList[rowIndex], constant?.birth_date) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => DateVal(filteredPlacementAccountList[rowIndex]?.birth_date, finalDateFormat?.birth_date, filteredPlacementAccountList[rowIndex], constant?.birth_date) != false);
        }

        // Remove Invalid balance Rows
        if (filteredHeaderList.findIndex(x => x == "total_balance") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => total_balance(filteredPlacementAccountList[rowIndex]?.total_balance, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => total_balance(filteredPlacementAccountList[rowIndex]?.total_balance, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid date Rows --Payments_Applied_Since_Itemization_Date
        if (filteredHeaderList.findIndex(x => x == "Payments_applied_since_itemization_date") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.Payments_applied_since_itemization_date, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.Payments_applied_since_itemization_date, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid date Rows --Credits_Applied_since_Itemization_Date
        if (filteredHeaderList.findIndex(x => x == "Credits_applied_since_itemization_date") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.Credits_applied_since_itemization_date, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.Credits_applied_since_itemization_date, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid date Rows --balance_owed_on_itemization
        if (filteredHeaderList.findIndex(x => x == "Balance_owed_on_itemization_date") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.Balance_owed_on_itemization_date, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.Balance_owed_on_itemization_date, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid date Rows --fees_added_since_itemization
        if (filteredHeaderList.findIndex(x => x == "Fees_added_since_itemization_date") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.Fees_added_since_itemization_date, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.Fees_added_since_itemization_date, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid date Rows --interest_added_since_itemization
        if (filteredHeaderList.findIndex(x => x == "Interest_added_since_itemization_date") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.Interest_added_since_itemization_date, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.Interest_added_since_itemization_date, filteredPlacementAccountList[rowIndex]) != false);
        }

        //Remove Invalid date Rows --Payments_Credit_Applied_Since_Itemization_Date
        if (filteredHeaderList.findIndex(x => x == "Payments_Credits_applied_since_itemization_date") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.Payments_Credits_applied_since_itemization_date, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.Payments_Credits_applied_since_itemization_date, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid Fee balance Rows
        if (filteredHeaderList.findIndex(x => x == "fees") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.fees, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex]?.fees, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid zip Rows
        if (filteredHeaderList.findIndex(x => x == "zip") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => zipVal(filteredPlacementAccountList[rowIndex]?.zip, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => zipVal(filteredPlacementAccountList[rowIndex]?.zip, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid state Rows
        if (filteredHeaderList.findIndex(x => x == "state") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => StateVal(filteredPlacementAccountList[rowIndex]?.state, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => StateVal(filteredPlacementAccountList[rowIndex]?.state, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid secondary_zip Rows
        if ((filteredHeaderList.findIndex(x => x == "secondary_zip") > -1) && (mappedRequireFields?.findIndex(x => x?.field_name === "secondary_zip") > -1)) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => zipVal(filteredPlacementAccountList[rowIndex]?.secondary_zip, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => zipVal(filteredPlacementAccountList[rowIndex]?.secondary_zip, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove Invalid Codebtor_Zip Rows
        if ((filteredHeaderList.findIndex(x => x == "Codebtor_Zip") > -1) && (mappedRequireFields?.findIndex(x => x?.field_name === "Codebtor_Zip") > -1)) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => zipVal(filteredPlacementAccountList[rowIndex]?.Codebtor_Zip, filteredPlacementAccountList[rowIndex]) != false);
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => zipVal(filteredPlacementAccountList[rowIndex]?.Codebtor_Zip, filteredPlacementAccountList[rowIndex]) != false);
        }

        // Remove first_name and last_name Invalid Rows
        if ((filteredHeaderList.includes("first_name") && filteredHeaderList.includes("last_name")) && (mappedRequireFields.find(x => x?.field_name === "first_name") && mappedRequireFields.find(x => x?.field_name === "last_name")) || filteredHeaderList.includes("full_name")) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleValidateName(filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleValidateName(filteredPlacementAccountList[rowIndex]));
        }

        // Remove conatctName Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "contact_name") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].contact_name, "contact_name", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].contact_name, "contact_name", filteredPlacementAccountList[rowIndex]));
        }

        // Remove conatctName Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "Codebtor_State") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_State, "Codebtor_State", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_State, "Codebtor_State", filteredPlacementAccountList[rowIndex]));
        }

        // Remove conatctName Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "secondary_phone_no") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_phone_no, "secondary_phone_no", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_phone_no, "secondary_phone_no", filteredPlacementAccountList[rowIndex]));
        }

        // Remove first_name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "first_name") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].first_name, "first_name", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].first_name, "first_name", filteredPlacementAccountList[rowIndex]));
        }

        // Remove last_name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "last_name") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].last_name, "last_name", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].last_name, "last_name", filteredPlacementAccountList[rowIndex]));
        }

        // Remove full_name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "full_name") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].full_name, "full_name", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].full_name, "full_name", filteredPlacementAccountList[rowIndex]));
        }

        // Remove address1 Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "address1") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].address1, "address1", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].address1, "address1", filteredPlacementAccountList[rowIndex]));
        }

        // Remove address2 Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "address2") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].address2, "address2", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].address2, "address2", filteredPlacementAccountList[rowIndex]));
        }

        // Remove city Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "city") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].city, "city", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].city, "city", filteredPlacementAccountList[rowIndex]));
        }

        // Remove secondary_address1 Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "secondary_address1") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_address1, "secondary_address1", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_address1, "secondary_address1", filteredPlacementAccountList[rowIndex]));
        }

        // Remove secondary_address2 Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "secondary_address2") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_address2, "secondary_address2", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_address2, "secondary_address2", filteredPlacementAccountList[rowIndex]));
        }

        // Remove secondary_city Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "secondary_city") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_city, "secondary_city", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_city, "secondary_city", filteredPlacementAccountList[rowIndex]));
        }

        // Remove email Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "email") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].email, "email", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].email, "email", filteredPlacementAccountList[rowIndex]));
        }

        // Remove drivers_license Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "drivers_license") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].drivers_license, "drivers_license", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].drivers_license, "drivers_license", filteredPlacementAccountList[rowIndex]));
        }

        // Remove tax_id Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "tax_id") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].tax_id, "tax_id", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].tax_id, "tax_id", filteredPlacementAccountList[rowIndex]));
        }
        // Remove guarantor_first_name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "guarantor_first_name") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].guarantor_first_name, "guarantor_first_name", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].guarantor_first_name, "guarantor_first_name", filteredPlacementAccountList[rowIndex]));
        }

        // Remove guarantor_last_name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "guarantor_last_name") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].guarantor_last_name, "guarantor_last_name", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].guarantor_last_name, "guarantor_last_name", filteredPlacementAccountList[rowIndex]));
        }

        // Remove guarantor_full_name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "guarantor_full_name") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].guarantor_full_name, "guarantor_full_name", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].guarantor_full_name, "guarantor_full_name", filteredPlacementAccountList[rowIndex]));
        }

        // Remove comment Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "comment") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].comment, "comment", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].comment, "comment", filteredPlacementAccountList[rowIndex]));
        }

        // Remove account_type Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "account_type") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].account_type, "account_type", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].account_type, "account_type", filteredPlacementAccountList[rowIndex]));
        }

        // Remove other Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "other") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].other, "other", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].other, "other", filteredPlacementAccountList[rowIndex]));
        }
        // Remove location Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "location") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].location, "location", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].location, "location", filteredPlacementAccountList[rowIndex]));
        }

        // Remove codebtor_Full_Name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "Codebtor_Full_Name") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_Full_Name, "Codebtor_Full_Name", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_Full_Name, "Codebtor_Full_Name", filteredPlacementAccountList[rowIndex]));
        }

        // Remove codebtor_Street_Address1 Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "Codebtor_Street_Address1") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_Street_Address1, "Codebtor_Street_Address1", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_Street_Address1, "Codebtor_Street_Address1", filteredPlacementAccountList[rowIndex]));
        }

        // Remove codebtor_Street_Address2 Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "Codebtor_Street_Address2") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_Street_Address2, "Codebtor_Street_Address2", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_Street_Address2, "Codebtor_Street_Address2", filteredPlacementAccountList[rowIndex]));
        }

        // Remove Codebtor_City Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "Codebtor_City") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_City, "Codebtor_City", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_City, "Codebtor_City", filteredPlacementAccountList[rowIndex]));
        }

        // Remove Codebtor_Tax_ID Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "Codebtor_Tax_ID") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_Tax_ID, "Codebtor_Tax_ID", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_Tax_ID, "Codebtor_Tax_ID", filteredPlacementAccountList[rowIndex]));
        }

        // Remove CoDebtor_First_Name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "CoDebtor_First_Name") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].CoDebtor_First_Name, "CoDebtor_First_Name", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].CoDebtor_First_Name, "CoDebtor_First_Name", filteredPlacementAccountList[rowIndex]));
        }

        // Remove city Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "CoDebtor_Last_Name") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].CoDebtor_Last_Name, "CoDebtor_Last_Name", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].CoDebtor_Last_Name, "CoDebtor_Last_Name", filteredPlacementAccountList[rowIndex]));
        }

        // Remove city Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "secondary_state") > -1) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_state, "secondary_state", filteredPlacementAccountList[rowIndex]));
            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_state, "secondary_state", filteredPlacementAccountList[rowIndex]));
        }

        //Remove Invalid Balance Rows
        if (mBalanceValue && +mBalanceValue > 0) {
            filteredCSVData = filteredCSVData.filter((item, rowIndex) =>
                filteredPlacementAccountList[rowIndex]?.total_balance >= parseFloat(mBalanceValue));

            filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) =>
                filteredPlacementAccountList[rowIndex].total_balance >= parseFloat(mBalanceValue));
        }

        // Remove ignored columns from csv data
        filteredCSVData.map((row, rowIndex) => {
            filteredCSVData[rowIndex] = filteredCSVData[rowIndex]?.filter((x, index) => filteredHeaderList[index] != "-1");
        });
        filteredHeaderList = filteredHeaderList.filter((x) => x != "-1");

        //Add FeeValue
        if (feeValue && +feeValue > 0) {
            if (filteredHeaderList.findIndex(x => x == "fees") == -1) { filteredHeaderList.push("fees"); }
            if (!filteredHeaderList.findIndex(x => x !== "fees")) { templateList.push(FeeObject); }
            filteredPlacementAccountList.map(x => { x.fees = feeValue; });
            filteredCSVData.map(x => { x?.push(feeValue); });
        }

        filteredCSVData.map((x, rowIndex) => {
            if ((filteredPlacementAccountList[rowIndex].delinquency_date?.length === dateLength || filteredPlacementAccountList[rowIndex].delinquency_date?.length === 20 || filteredPlacementAccountList[rowIndex].delinquency_date?.length === 17) && !filteredPlacementAccountList[rowIndex].delinquency_date?.includes('-') && !filteredPlacementAccountList[rowIndex].delinquency_date?.includes('/')) {
                let dateValue = filteredPlacementAccountList[rowIndex].delinquency_date?.substring(0, 4) + '/' + filteredPlacementAccountList[rowIndex].delinquency_date?.substring(6, 2)?.slice(2) + '/' + filteredPlacementAccountList[rowIndex].delinquency_date?.substring(8, 4)?.slice(2);
                filteredPlacementAccountList[rowIndex].delinquency_date = dateValue;
            }
            if ((filteredPlacementAccountList[rowIndex].Itemization_Date?.length === dateLength || filteredPlacementAccountList[rowIndex].Itemization_Date?.length === 20 || filteredPlacementAccountList[rowIndex].Itemization_Date?.length === 17) && !filteredPlacementAccountList[rowIndex].Itemization_Date?.includes('-') && !filteredPlacementAccountList[rowIndex].Itemization_Date?.includes('/')) {
                let dateValue = filteredPlacementAccountList[rowIndex].Itemization_Date?.substring(0, 4) + '/' + filteredPlacementAccountList[rowIndex].Itemization_Date?.substring(6, 2)?.slice(2) + '/' + filteredPlacementAccountList[rowIndex].Itemization_Date?.substring(8, 4)?.slice(2);
                filteredPlacementAccountList[rowIndex].Itemization_Date = dateValue;
            }
            if ((filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.length === dateLength || filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.length === 20 || filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.length === 17) && !filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.includes('-') && !filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.includes('/')) {
                let dateValue = filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.substring(0, 4) + '/' + filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.substring(6, 2)?.slice(2) + '/' + filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.substring(8, 4)?.slice(2);
                filteredPlacementAccountList[rowIndex].Codebtor_BirthDate = dateValue;
            }
            if ((filteredPlacementAccountList[rowIndex].last_payment_date?.length === dateLength || filteredPlacementAccountList[rowIndex].last_payment_date?.length === 20 || filteredPlacementAccountList[rowIndex].last_payment_date?.length === 17) && !filteredPlacementAccountList[rowIndex].last_payment_date?.includes('-') && !filteredPlacementAccountList[rowIndex].last_payment_date?.includes('/')) {
                let dateValue = filteredPlacementAccountList[rowIndex].last_payment_date?.substring(0, 4) + '/' + filteredPlacementAccountList[rowIndex].last_payment_date?.substring(6, 2)?.slice(2) + '/' + filteredPlacementAccountList[rowIndex].last_payment_date?.substring(8, 4)?.slice(2);
                filteredPlacementAccountList[rowIndex].last_payment_date = dateValue;
            }
            if ((filteredPlacementAccountList[rowIndex].service_date?.length === dateLength || filteredPlacementAccountList[rowIndex].service_date?.length === 20 || filteredPlacementAccountList[rowIndex].service_date?.length === 17) && !filteredPlacementAccountList[rowIndex].service_date?.includes('-') && !filteredPlacementAccountList[rowIndex].service_date?.includes('/')) {
                let dateValue = filteredPlacementAccountList[rowIndex].service_date?.substring(0, 4) + '/' + filteredPlacementAccountList[rowIndex].service_date?.substring(6, 2)?.slice(2) + '/' + filteredPlacementAccountList[rowIndex].service_date?.substring(8, 4)?.slice(2);
                filteredPlacementAccountList[rowIndex].service_date = dateValue;
            }
            if ((filteredPlacementAccountList[rowIndex].birth_date?.length === dateLength || filteredPlacementAccountList[rowIndex].birth_date?.length === 20 || filteredPlacementAccountList[rowIndex].birth_date?.length === 17) && !filteredPlacementAccountList[rowIndex].birth_date?.includes('-') && !filteredPlacementAccountList[rowIndex].birth_date?.includes('/')) {
                let dateValue = filteredPlacementAccountList[rowIndex].birth_date?.substring(0, 4) + '/' + filteredPlacementAccountList[rowIndex].birth_date?.substring(6, 2)?.slice(2) + '/' + filteredPlacementAccountList[rowIndex].birth_date?.substring(8, 4)?.slice(2);
                filteredPlacementAccountList[rowIndex].birth_date = dateValue;
            }
            if (filteredPlacementAccountList[rowIndex].zip?.length === zipLength && !filteredPlacementAccountList[rowIndex].zip?.includes('-')) {
                let zipValue = ('0' + filteredPlacementAccountList[rowIndex].zip);
                filteredPlacementAccountList[rowIndex].zip = zipValue;
            }
            if (filteredPlacementAccountList[rowIndex].secondary_zip?.length === zipLength && !filteredPlacementAccountList[rowIndex].secondary_zip?.includes('-')) {
                let zipValue = ('0' + filteredPlacementAccountList[rowIndex].secondary_zip);
                filteredPlacementAccountList[rowIndex].secondary_zip = zipValue;
            }
            if (filteredPlacementAccountList[rowIndex].Codebtor_Zip?.length === zipLength && !filteredPlacementAccountList[rowIndex].Codebtor_Zip?.includes('-')) {
                let zipValue = ('0' + filteredPlacementAccountList[rowIndex].Codebtor_Zip);
                filteredPlacementAccountList[rowIndex].Codebtor_Zip = zipValue;
            }
        });

        const columnsToRemove = ['user_id', 'modifiedby'];
        const csvData1 = filteredRejectedCSVData?.length != 0 ? convertListToCSV(filteredRejectedCSVData, columnsToRemove) : null;
        const csvData2 = ruleRejectedAccounts?.length ? convertRuleRejectedListtoCSVFile(columnsToRemove) : null;
        const csvUrl = mergeCSVResults(csvData1, csvData2);
        const totalRecords = (filteredRejectedCSVData?.length || 0) + (ruleRejectedAccounts?.length || 0) + (placementDuplicateAccountsList?.length || 0);

        setRejectedAccountList(totalRecords);
        setCSVUrl(csvUrl);
    }

    function phase1(phaseString: string, currentAccountData: any): boolean {
        var phaseStringValue = phaseString.trim();
        return phaseStringValue == constant.PREC || phaseStringValue == constant.CONT
            || phaseStringValue == constant.FIRSTSTP;
    }

    function total_balance(balance: any, currentAccountData: any): boolean {
        var totalBalanceValue = balance?.toString()?.trim();
        let accountNumberValue = currentAccountData?.account_no;

        let newResult = constant.totalBalanceReg.test(totalBalanceValue) ? true : false;
        if (!newResult) {
            const exist = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
            const newData = { ...currentAccountData };
            newData['Rejected Reason'] = 'Currency Field should contain numbers , dollar symbol, hyphen and periodic symbol'; // Add new column for rejected Accounts and adding rejected Reason in the List
            if (!exist) {
                filteredRejectedCSVData?.push(newData);
            }
        }
        return (constant.totalBalanceReg.test(totalBalanceValue) ? true : false);
    }

    function phone_no(phone: string, currentAccountData: any): boolean {
        var PhoneValue;
        let accountNumberValue = currentAccountData?.account_no;
        if (phone !== null || phone !== "") {
            PhoneValue = phone?.replace(constant.phoneRegexVal, "");
        }
        PhoneValue = PhoneValue?.trim();

        let newResult = constant.phoneVal.test(PhoneValue) ? true : false;
        if (!newResult) {
            const exist = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
            const newData = { ...currentAccountData };
            newData['Rejected Reason'] = 'Phone Number Must be Valid'; // Add new column for rejected Accounts and adding rejected Reason in the List
            if (!exist) {
                filteredRejectedCSVData?.push(newData);
            }
        }
        return (constant.phoneVal.test(PhoneValue) ? true : false);
    }

    function client_code(clientCodeString: string, currentAccountData: any): boolean {
        var ClientCodeValue = clientCodeString?.trim();
        let accountNumberValue = currentAccountData?.account_no;

        let newResult = clientList.findIndex(x => x.clT_CODE == ClientCodeValue) > -1;
        if (!newResult) {
            const exist = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
            const newData = { ...currentAccountData };
            newData['Rejected Reason'] = 'Client Code Must be six characters'; // Add new column for rejected Accounts and adding rejected Reason in the List
            if (!exist) {
                filteredRejectedCSVData?.push(newData);
            }
        }
        return clientList.findIndex(x => x.clT_CODE == ClientCodeValue) > -1;
    }

    function currencyVal(balance: any, currentAccountData: any): boolean {
        var balanceValue = balance?.toString()?.trim();
        let accountNumberValue = currentAccountData?.account_no;

        let newResult = constant.currencyReg?.test(balanceValue) ? true : false;
        if (!newResult) {
            const exist = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
            const newData = { ...currentAccountData };
            newData['Rejected Reason'] = 'Currency Field should contain numbers , dollar symbol and hyphen'; // Add new column for rejected Accounts and adding rejected Reason in the List
            if (!exist) {
                filteredRejectedCSVData?.push(newData);
            }
        }
        return (constant.currencyReg?.test(balanceValue) ? true : false);
    }

    function FutureDateVal(dateString: string, dateFieldName: string, currentAccountData: any, currentFieldName: string): boolean {
        let dateValue = dateString?.trim();
        let accountNumberValue = currentAccountData?.account_no;
        var last4 = "";
        let yearValue;
        var newDateFormat = "";
        const splitConditions = /[-/\s]+/;
        if ((dateString?.length === 8 || dateString?.length === 20 || dateString?.length === 17) && !dateString?.includes('-') && !dateString?.includes('/')) {
            dateValue = dateString?.substring(0, 4) + '/' + dateString?.substring(6, 2)?.slice(2) + '/' + dateString?.substring(8, 4)?.slice(2);
        }
        if (dateString?.length === 8 && !dateString?.includes('-') && !dateString?.includes('/')) {
            last4 = dateValue?.substring(0, 4);
            yearValue = Number(last4);
        } else {
            if (dateValue != undefined) {
                const dateParts = dateValue?.split(splitConditions);
                if (dateFieldName === "YYYY/MM/DD" || dateFieldName === "YYYY/DD/MM") {
                    last4 = dateParts[0];
                    yearValue = Number(last4);
                } else {
                    last4 = dateParts[2];
                    yearValue = Number(last4);
                    newDateFormat = dateParts[0] + "-" + dateParts[1] + "-" + dateParts[2]
                }
            }
        }
        let givenDate;
        let currentDate;
        if (dateFieldName === "DD/MM/YYYY") {
            const [day, month, year] = newDateFormat?.split(splitConditions); // get the day month and year based on the file format
            currentDate = new Date(); // gets the current date
            givenDate = new Date(`${year}-${month}-${day}`); // replace with the given date you want to check
        }

        var SubtractedDate = Number(moment(new Date()).subtract(100, 'year')?.format('YYYY'));
        let invalidDate: boolean;
        if (last4?.length === 2) {
            invalidDate = true;
        } else {
            invalidDate = ((yearValue < SubtractedDate) ? false : true);
        }
        let result: any;
        // validate the each records based on the source date format choosen by the user or from the template
        if (dateFieldName === "DD/MM/YYYY") {
            result = ((moment(dateValue, constant?.dayMonthFormat, true).isValid()) && !((givenDate > currentDate)) ? true : false);
        } else if (dateFieldName === "YYYY/MM/DD") {
            result = ((moment(dateValue, constant?.yearMonthFormat, true).isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
        } else if (dateFieldName === "YYYY/DD/MM") {
            result = ((moment(dateValue, constant?.yearDateFormat, true).isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
        } else if (dateFieldName === "MM/DD/YYYY") {
            result = ((moment(dateValue, constant?.monthDayFormat, true).isValid()) && (!moment(newDateFormat)?.isAfter(new Date())) ? true : false);
        }
        if (result) { setFutureVal(result && !futureVal ? true : result ? true : false); };

        let newResult = invalidDate && result ? true : false;
        if (!newResult) {
            const exist = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
            const newData = { ...currentAccountData };
            newData['Rejected Reason'] = currentFieldName + ' Should not exceed 100 year or future date or empty and it should be a valid date'; // Add new column for rejected Accounts and adding rejected Reason in the List
            if (!exist) {
                filteredRejectedCSVData?.push(newData);
            }
        }
        return (invalidDate && result ? true : false);
    }

    function ItemizationFutureDateValidation(dateString: string, dateFieldName: string, currentAccountData: any, currentFieldName: string): boolean {
        let dateValue = dateString?.trim();
        let accountNumberValue = currentAccountData?.account_no;
        var last4 = "";
        let yearValue;
        var newDateFormat = "";
        const splitConditions = /[-/\s]+/;
        if ((dateString?.length === 8 || dateString?.length === 20 || dateString?.length === 17) && !dateString?.includes('-') && !dateString?.includes('/')) {
            dateValue = dateString?.substring(0, 4) + '/' + dateString?.substring(6, 2)?.slice(2) + '/' + dateString?.substring(8, 4)?.slice(2);
        }
        if (dateString?.length === 8 && !dateString?.includes('-') && !dateString?.includes('/')) {
            last4 = dateValue?.substring(0, 4);
            yearValue = Number(last4);
        } else {
            if (dateValue != undefined) {
                const dateParts = dateValue?.split(splitConditions);
                if (dateFieldName === "YYYY/MM/DD" || dateFieldName === "YYYY/DD/MM") {
                    last4 = dateParts[0];
                    yearValue = Number(last4);
                } else {
                    last4 = dateParts[2];
                    yearValue = Number(last4);
                    newDateFormat = dateParts[0] + "-" + dateParts[1] + "-" + dateParts[2]
                }
            }
        }
        let givenDate;
        let currentDate;
        if (dateFieldName === "DD/MM/YYYY") {
            const [day, month, year] = newDateFormat?.split(splitConditions); // get the day month and year based on the file format
            currentDate = new Date(); // gets the current date
            givenDate = new Date(`${year}-${month}-${day}`); // replace with the given date you want to check
        }

        var SubtractedDate = Number(moment(new Date()).subtract(100, 'year')?.format('YYYY'));
        let invalidDate: boolean;
        if (last4?.length === 2) {
            invalidDate = true;
        } else {
            if ((isClientCodeExempt?.isAuditLetter >= 1 && getPhaseString(phaseNumber) === constant.PREC) || isClientCodeExempt?.isClientCodeIsExempted != 0) {
                invalidDate = true;
            } else {
                invalidDate = ((yearValue < SubtractedDate) ? false : true);
            }
        }
        let result: any;
        // validate the each records based on the source date format choosen by the user or from the template
        if (dateFieldName === "DD/MM/YYYY") {
            if ((isClientCodeExempt?.isAuditLetter >= 1 && getPhaseString(phaseNumber) === constant.PREC) || isClientCodeExempt?.isClientCodeIsExempted != 0) {
                result = true;
            } else {
                result = ((moment(dateValue, constant?.dayMonthFormat, true).isValid()) && !((givenDate > currentDate)) ? true : false);
            }
        } else if (dateFieldName === "YYYY/MM/DD") {
            if ((isClientCodeExempt?.isAuditLetter >= 1 && getPhaseString(phaseNumber) === constant.PREC) || isClientCodeExempt?.isClientCodeIsExempted != 0) {
                result = true;
            } else {
                result = ((moment(dateValue, constant?.yearMonthFormat, true).isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
            }
        } else if (dateFieldName === "YYYY/DD/MM") {
            if ((isClientCodeExempt?.isAuditLetter >= 1 && getPhaseString(phaseNumber) === constant.PREC) || isClientCodeExempt?.isClientCodeIsExempted != 0) {
                result = true;
            } else {
                result = ((moment(dateValue, constant?.yearDateFormat, true).isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
            }
        } else if (dateFieldName === "MM/DD/YYYY") {
            if ((isClientCodeExempt?.isAuditLetter >= 1 && getPhaseString(phaseNumber) === constant.PREC) || isClientCodeExempt?.isClientCodeIsExempted != 0) {
                result = true;
            } else {
                result = ((moment(dateValue, constant?.monthDayFormat, true).isValid()) && (!moment(newDateFormat)?.isAfter(new Date())) ? true : false);
            }
        }

        if (result) { setFutureVal(result && !futureVal ? true : result ? true : false); };

        let newResult = invalidDate && result ? true : false;
        if (!newResult) {
            const exist = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
            const newData = { ...currentAccountData };
            newData['Rejected Reason'] = currentFieldName + ' Should not exceed 100 year or future date or empty and it should be a valid date'; // Add new column for rejected Accounts and adding rejected Reason in the List
            if (!exist) {
                filteredRejectedCSVData?.push(newData);
            }
        }
        return (invalidDate && result ? true : false);
    }

    function DateValidation(dateString: string, dateFieldName: string, currentAccountData: any, currentFieldName: string): boolean {
        let dateValue = dateString?.trim();
        let accountNumberValue = currentAccountData?.account_no;
        var last4 = "";
        let yearValue;
        var newDateFormat = "";
        if ((dateString?.length === 8 || dateString?.length === 20 || dateString?.length === 17) && !dateString?.includes('-') && !dateString?.includes('/')) {
            dateValue = dateString?.substring(0, 4) + '/' + dateString?.substring(6, 2)?.slice(2) + '/' + dateString?.substring(8, 4)?.slice(2);
        }
        if (dateString?.length === 8 && !dateString?.includes('-') && !dateString?.includes('/')) {
            last4 = dateValue?.substring(0, 4);
            yearValue = Number(last4);
        } else {
            const splitConditions = /[-/\s]+/;
            if (dateValue != undefined) {
                const dateParts = dateValue?.split(splitConditions);
                if (dateFieldName === "YYYY/MM/DD" || dateFieldName === "YYYY/DD/MM") {
                    last4 = dateParts[0];
                    yearValue = Number(last4);
                } else {
                    last4 = dateParts[2];
                    yearValue = Number(last4);
                    newDateFormat = dateParts[0] + "/" + dateParts[1] + "/" + dateParts[2]
                }
            }
        }
        var SubtractedDate = Number(moment(new Date())?.subtract(100, 'year')?.format('YYYY'));
        let invalidDate = ((yearValue < SubtractedDate) ? false : true);

        let result: any;
        // validate the each records based on the source date format choosen by the user or from the template
        if (dateFieldName === "DD/MM/YYYY") {
            result = ((moment(dateValue, constant?.dayMonthFormat, true)?.isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
        } else if (dateFieldName === "YYYY/MM/DD") {
            result = ((moment(dateValue, constant?.yearMonthFormat, true)?.isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
        } else if (dateFieldName === "YYYY/DD/MM") {
            result = ((moment(dateValue, constant?.yearDateFormat, true)?.isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
        } else if (dateFieldName === "MM/DD/YYYY") {
            result = ((moment(dateValue, constant?.monthDayFormat, true)?.isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
        }
        if (result) { setFutureVal(result && !futureVal ? true : result ? true : false); };

        let newResult = (dateValue === "" || dateValue === null) ? false : invalidDate && result ? true : false;
        if (!newResult) {
            const exist = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
            const newData = { ...currentAccountData };
            newData['Rejected Reason'] = currentFieldName + ' Should not exceed 100 year or future date or empty'; // Add new column for rejected Accounts and adding rejected Reason in the List
            if (!exist) {
                filteredRejectedCSVData?.push(newData);
            }
        }
        return ((dateValue === "" || dateValue === null) ? false : invalidDate && result ? true : false);
    }

    function DateVal(dateString: string, dateFieldName: string, currentAccountData: any, currentFieldName: string): boolean {
        let accountNumberValue = currentAccountData?.account_no;
        let dateValue = dateString?.trim();
        if ((dateString?.length === 8 || dateString?.length === 20 || dateString?.length === 17) && !dateString?.includes('-') && !dateString?.includes('/')) {
            dateValue = dateString?.substring(0, 4) + '/' + dateString?.substring(6, 2)?.slice(2) + '/' + dateString?.substring(8, 4)?.slice(2);
        }

        // Allow empty date values
        if (dateValue === "" || dateValue === null) {
            return true;
        }

        let result: any;
        // validate the each records based on the source date format choosen by the user or from the template
        if (dateFieldName === "DD/MM/YYYY") {
            result = (moment(dateValue, constant?.dayMonthFormat, true)?.isValid()) ? true : false
        } else if (dateFieldName === "YYYY/MM/DD") {
            result = (moment(dateValue, constant?.yearMonthFormat, true)?.isValid()) ? true : false
        } else if (dateFieldName === "YYYY/DD/MM") {
            result = (moment(dateValue, constant?.yearDateFormat, true)?.isValid()) ? true : false
        } else if (dateFieldName === "MM/DD/YYYY") {
            result = (moment(dateValue, constant?.monthDayFormat, true)?.isValid()) ? true : false
        }

        if (!result) {
            const exist = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
            const newData = { ...currentAccountData };
            newData['Rejected Reason'] = currentFieldName + ' should be a valid date';
            if (!exist) {
                filteredRejectedCSVData?.push(newData);
            }
        }

        return result;
    }

    function zipVal(zipCode: string, currentAccountData: any): boolean {
        var zipValue = zipCode?.trim();
        let accountNumberValue = currentAccountData?.account_no;
        if (zipValue?.length === 4 && !zipValue?.includes('-')) { zipCode = ('0' + zipValue); };
        let newResult = constant.zipVal?.test(zipValue) ? true : false;
        if (!newResult) {
            const exist = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
            const newData = { ...currentAccountData };
            newData['Rejected Reason'] = 'Zip code must be Alpha numeric Values and maximum characters should be 5 or 9 excluding hyphen and brackets'; // Add new column for rejected Accounts and adding rejected Reason in the List
            if (!exist) {
                filteredRejectedCSVData?.push(newData);
            }
        }
        return (constant.zipVal?.test(zipValue) ? true : false);
    }

    function StateVal(StateCode: string, currentAccountData: any): boolean {
        var StateValue = StateCode?.trim();
        let accountNumberValue = currentAccountData?.account_no;
        let newResult = constant.StateReg?.test(StateValue) ? true : false;
        if (!newResult) {
            const exist = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
            const newData = { ...currentAccountData };
            newData['Rejected Reason'] = 'State Value must be two characters'; // Add new column for rejected Accounts and adding rejected Reason in the List
            if (!exist) {
                filteredRejectedCSVData?.push(newData);
            }
        }
        return (constant.StateReg?.test(StateValue) ? true : false);
    }

    function validateAccountNumber(accountNumber: string, currentAccountData: any): boolean {
        var accountNumberValue = accountNumber?.trim();
        let newResult = constant.accountNumberRegex?.test(accountNumberValue) ? true : false;
        if (!newResult) {
            const exist = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
            const newData = { ...currentAccountData };
            newData['Rejected Reason'] = 'Account number does not  support percent sign, underscore, and apostrophe and maximum characters should be 50'; // Add new column for rejected Accounts and adding rejected Reason in the List
            if (!exist) {
                filteredRejectedCSVData?.push(newData);
            }
        }
        return (constant.accountNumberRegex?.test(accountNumberValue) ? true : false);
    }

    function handleMaxLengthValidation(currentData: string, currentHeaderValue: string, currentAccountData: any): boolean {
        let result = false;
        const accountNumberValue = currentAccountData?.account_no;
        templateList?.forEach((a) => {
            if (a?.field_name === currentHeaderValue) {
                const regexPattern = `^(.{0,${a?.field_length}})$`;
                const regex = new RegExp(regexPattern);
                const newResult = regex?.test(currentData);
                if (!newResult) {
                    const exist = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
                    currentAccountData['Rejected Reason'] = `${a?.display_name} should not exceed ${a?.field_length} characters`;
                    if (!exist) {
                        filteredRejectedCSVData?.push(currentAccountData);
                    }
                }
                result = regex?.test(currentData);
            }
        });
        return result;
    }

    function handleValidateName(currentAccountData: any): boolean {

        const isFullNameMapped = filteredHeaderList.includes("full_name");
        const isFirstLastNameMapped = (filteredHeaderList.includes("first_name") && filteredHeaderList.includes("last_name")) && (mappedRequireFields.find(x => x?.field_name === "first_name") && mappedRequireFields.find(x => x?.field_name === "last_name"));
        const firstName = currentAccountData?.first_name;
        const lastName = currentAccountData?.last_name;
        const fullName = currentAccountData?.full_name;

        // Case 1: Only full_name is mapped, check if full_name is null or empty
        if (isFullNameMapped && !isFirstLastNameMapped) {
            if (!fullName || fullName.trim() === "") {
                const accountNumberValue = currentAccountData?.account_no;
                const accountExists = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
                const rejectedAccount = { ...currentAccountData };
                rejectedAccount['Rejected Reason'] = 'full_name is required and cannot be empty';
                if (!accountExists) {
                    filteredRejectedCSVData.push(rejectedAccount);
                }
                return false;
            }
        }

        // Case 2: Both first_name and last_name are mapped, but full_name is not mapped
        if (!isFullNameMapped && isFirstLastNameMapped) {
            if ((!firstName || firstName.trim() === "") && (!lastName || lastName.trim() === "")) {
                const accountNumberValue = currentAccountData?.account_no;
                const accountExists = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
                const rejectedAccount = { ...currentAccountData };
                rejectedAccount['Rejected Reason'] = 'first_name and last_name are required and cannot be empty';
                if (!accountExists) {
                    filteredRejectedCSVData.push(rejectedAccount);
                }
                return false;
            }
        }

        // Case 3: All three (full_name, first_name, and last_name) are mapped, check if all are empty
        if (isFullNameMapped && isFirstLastNameMapped) {
            if ((!firstName || firstName.trim() === "") && (!lastName || lastName.trim() === "") && (!fullName || fullName.trim() === "")) {
                const accountNumberValue = currentAccountData?.account_no;
                const accountExists = filteredRejectedCSVData.some((a) => accountNumberValue === a?.account_no);
                const rejectedAccount = { ...currentAccountData };
                rejectedAccount['Rejected Reason'] = 'Full_name, first_name, and last_name are required and cannot be empty';
                if (!accountExists) {
                    filteredRejectedCSVData.push(rejectedAccount);
                }
                return false;
            }
        }
        return true;
    }

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={state.busy ? true : false}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <CssBaseline />
                <div>
                    {csvUrl != null ?
                        <Button style={{ marginTop: '0px' }} id="CSVPD_submit_btn" size="small" fullWidth startIcon={<DownloadIcon />} variant="outlined" sx={{ mt: 1 }} className="blinkButton" color="error">
                            <a href={csvUrl}
                                style={{ color: '#d32f2f', textDecoration: 'none', fontSize: '12px' }}
                                download={`Rejected_Accounts_Details-${clientCode}-${getPhaseString(phaseNumber)}-${moment(new Date()).format("MM-DD-YYYY")}.csv`}><b>Download Rejected Accounts : {rejectedAccountList}</b>
                            </a>
                        </Button>
                        : null
                    }
                </div>
            </div>
        </React.Fragment >
    )
}

export default withRouter(DownloadRejectedAccounts)
