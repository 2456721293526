import {
    Box, Button, Checkbox, CheckboxProps, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControlLabel, Grid, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Paper, Table, TableBody,
    TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, useMediaQuery
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { useTheme, withStyles } from '@material-ui/core/styles';
import AlertMessage from '@mui/material/Alert';
// Icons
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FileIcon from '@material-ui/icons/FileCopy';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import SearchIcon from "@material-ui/icons/Search";
import ArchiveFile from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ExportLog from '@mui/icons-material/DriveFileMove';
import ErrorIcon from '@mui/icons-material/Error';
import FileDownload from '@mui/icons-material/FileDownload';
import ViewAuditLog from '@mui/icons-material/FindInPage';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
// Image Iocns
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import download from "../../images/files/download01.svg";
import downloadFiles from "../../images/files/downloadFiles.svg";
import fileArchive from "../../images/files/fileArchive.svg";
import fileArchiveDisable from "../../images/files/fileArchiveDisable.svg";
import upload from "../../images/files/uploading.svg";
import ExportFileLog from '../../images/webviewfiles/ExportLog.svg';
import archivefile from '../../images/webviewfiles/archivefile.png';
import cloudUpload from '../../images/webviewfiles/cloudUpload.svg';
import cloudnotUpload from '../../images/webviewfiles/cloudnotUpload.svg';
import downloadDisable from "../../images/webviewfiles/download-Disable.svg";
import fileUploaded from '../../images/webviewfiles/fileUploaded.svg';
import massUpload from '../../images/webviewfiles/massUpload.svg';
import norecord from '../../images/webviewfiles/no-results.svg';
import notUploaded from '../../images/webviewfiles/notUploaded.svg';
import notVerified from '../../images/webviewfiles/notVerified.svg';
import verified from '../../images/webviewfiles/verified.svg';
// Componant
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { styled } from '@mui/material/styles';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Footer from '../Footer/Footer';
import LightTooltip from '../GlobalStyles/LightTooltip';
import TooltipSmall from '../GlobalStyles/TooltipSmall';
import BreadCrumbs from "./BreadCrumbs";
import FileLogRequest from './FileLogRequest';
// Models
import { Skeleton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import Switch, { SwitchProps } from '@mui/material/Switch';
import axios from 'axios';
import clsx from 'clsx';
import _ from 'lodash';
import { CSVLink } from "react-csv";
import { Scrollbars } from 'react-custom-scrollbars';
import { FileNameVal, MassFileTrigger, apiKey, PlacementRejectedFileID } from '../../constants/Constant';
import { IBlobFiles } from '../../models/Files/IBlobFiles';
import { IContainerResult } from '../../models/Files/IContainerResult';
import { IFTPSubFolder } from '../../models/Files/IFTPSubFolder';
import { IFileDetails } from '../../models/Files/IFileDetails';
import { IFileExport } from '../../models/Files/IFileExport';
import { IMultiDownload } from '../../models/Files/IMultiDownload';
import { ISubFolder } from '../../models/Files/ISubFolder';
import { IWebViewFiles } from '../../models/Files/IWebViewFiles';
import { IUserInvited } from '../../models/common/IUserInvited';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import AvatarTooltip from '../GlobalStyles/AvatarTooltip';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete, DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import MoveFileErrorToProcessFolder from './MoveFileErrorToProcessFolder';
import useStyles from './WebViewFilesCss';
import { trustStatementAction } from '../../models/accesscontrol/ClientUserAccessContext';
import { IClientInfo } from '../../models/client/IClientInfo';
import { ErrorReason } from '../../models/webviewFiles/IErrorAction';
import DocumentPDFViewer from './DocumentPDFViewer';
import { Typography } from '@mui/material';

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
})((props: CheckboxProps) => <Checkbox size='small' color="default" {...props} />);

const ArchiveSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const FileManager = () => {
    const theme = useTheme();
    const classes = useStyles();
    let fileUpload = useRef(null);
    const { state } = useGlobalState();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [ListContainers, setListContainers] = useState<IContainerResult[]>([]);
    const [SelectedContainer, setSelectedContianer] = useState<IContainerResult[]>([]);

    const [ftpSubContainers, setFTPSubContainers] = useState<IFTPSubFolder[]>([]);
    const [selectedftpSubContainer, setSelectedftpSubContianer] = useState<IFTPSubFolder[]>([]);

    const [listSubContainers, setListSubContainers] = useState<ISubFolder[]>([]);
    const [selectedSubContainer, setSelectedSubContianer] = useState<ISubFolder[]>([]);

    const [selectedFile, setSelectedFile] = useState<IMultiDownload[]>([]);
    const [listWebViewFiles, setListWebViewFiles] = useState<IWebViewFiles[]>([]);
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
    const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);

    const [exportAudit, setExportAudit] = useState<IFileExport[]>([]);
    const [subFolderNameList, setSubFolderNameList] = useState<IClientInfo[]>([]);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(false);
    const [isExists, setIsExists] = useState(false);
    const [isExpanded, setIsEpanded] = useState(true);
    const [mulLoding, setMulLoading] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const [ftpLoading, setFTPLoading] = useState(false);
    const [downloadPro, setDownloadPro] = useState(false);
    const [isDownload, setIsDownloaded] = useState(false);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [subFolderExpand, setsubFolderExpand] = useState(false);
    const [ftpsubFolderExpand, setFTPsubFolderExpand] = useState(false);
    const [multiArchive, setMultiArchive] = useState(false);
    const [multiDownload, setMultiDownload] = useState(false);
    const [uploadFiles, setUploadFiles] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [IsFileExists, setisFileExists] = useState(false);
    const [isExport, setisExport] = useState(false);

    const [selected, setSelected] = useState(0);
    const [order, setOrder] = useState<any>('desc');
    const [searchFile, setSearchFile] = useState("");
    const [orderBy, setOrderBy] = useState<string>("created_date");
    const [fileName, setFileName] = useState("");

    const [fId, setFId] = useState(0);
    const [page, setPage] = useState(0);
    const [subFId, setSubFId] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [isArchive, setIsArchive] = useState(false);
    const [isSeached, setIsSearched] = useState(false);
    const [isFolderExists, setIsFolderExists] = useState(0);
    const [newFileID, setNewFileID] = useState<number>(0);
    const [isSeachedSubFolder, setIsSeachedSubFolder] = useState(false);
    const [searchSubFolderName, setSearchSubFolderName] = useState("");
    const [showSearchSunFolderIcon, setShowSearchSubFolderIcon] = useState(true);

    const timer = useRef<number>();
    const [openDialogBox, setOpenDialogBox] = useState(false);
    const [openTimeLine, setOpenTimeLine] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [UserVerify, setUserVerify] = useState(false);
    const [message, setMessage] = useState<string>('');
    const [VerifyFiles, setVerifyFiles] = useState(false);
    const [UploadMassFiles, setUploadMassFiles] = useState(false);
    const [isTriggerFile, setIsTriggerFile] = useState(false);
    const [unarchive, setUnarchieve] = useState(false);

    const ftpContainerWithMoveIcon = selectedSubContainer?.some((r) => r?.folder_Id === 9);

    let newFilesList = [];

    const headers = [
        {
            label: "Action Name",
            key: "actionName"
        },
        {
            label: "Action Text",
            key: "actionText"
        },
        {
            label: "Role",
            key: "role"
        },
        {
            label: "Created Date",
            key: "created_Date"
        },
        {
            label: "Modified Date",
            key: "modified_Date"
        },
        {
            label: "File Status",
            key: "file_Status_Name"
        }
    ]

    // #Start region Snack Bar
    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };

    const handleCloseMultiDownload = () => {
        setMultiDownload(false);
    };

    const handleCloseDialog = () => {
        setMultiArchive(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleExportClose = () => {
        setisExport(false);
    }

    const handleCloseUpload = () => {
        setUploadFiles(false);
        setAcceptedFiles([]);
        setRejectedFiles([]);
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsDownloaded(false);
        setIsExists(false);
    };
    // #End region Snack Bar

    const handleMainFolderToggler = () => {
        setIsEpanded(s => !s);
    };

    const handleSubFolderToggler = () => {
        setsubFolderExpand(s => !s);
    };

    const handleFTPSubFolderToggler = () => {
        setFTPsubFolderExpand(s => !s);
    };

    const handleRowClick = (fileId: number) => {
        setSelected(selected == fileId ? null : fileId);
    };

    const handleArchiveFile = (fileId: number) => {
        if (selectedFile?.length <= 0) {
            setNewFileID(fileId);
            setOpen(true);
        } else {
            setOpen(true);
        }
    };

    const handleTrustStatementArchieveFile = (fileData: IWebViewFiles) => {
        (async () => {
            let request = {
                "fileId": fileData?.fileId,
                "actionId": trustStatementAction?.FileArchieved,
                "userId": state?.userAccessContext?.id,
                "errorId": null
            }
            await usePost<any>("TrustStatementAuditHistory", request);
        })().finally(() => {
            GetFilesAsync();
        })
    }

    useEffect(() => {
        ContainerListAsync();
    }, [])

    const ContainerListAsync = () => {
        setFTPSubContainers([]);
        setListSubContainers([]);
        setSelectedftpSubContianer([]);
        setSelectedSubContianer([]);
        (async () => {
            setLoading(true);
            setProgress(true);
            await useFetch<IContainerResult[]>("GlobalSettings/GetWebViewContainers").then((r) => {
                let defaultContainers = [...SelectedContainer];
                defaultContainers.push(r?.data[0]);
                setSelectedContianer(defaultContainers);
                setListContainers(r?.data);
            }).finally(() => {
                setLoading(false);
            });
        })()
    };

    useEffect(() => {
        SubFolderListAsync();
    }, [SelectedContainer[0]?.containerId])

    const SubFolderListAsync = () => {
        if (SelectedContainer[0]?.containerId !== undefined || SelectedContainer?.length !== 0) {
            (async () => {
                setProgress(true);
                setSubLoading(true);
                let request = {
                    "containerId": SelectedContainer[0]?.containerId
                }
                await usePost<{ "cloudFolders": ISubFolder[], isExists: number }>("AuditLog/GetCloudFolders", request).then((r) => {
                    setFId(r?.data["cloudFolders"][0]?.folder_Id);
                    setListSubContainers(r?.data["cloudFolders"]);
                    setIsFolderExists(r?.data?.isExists);
                    setsubFolderExpand(r?.data?.isExists > 0 ? true : false);
                    setSelectedSubContianer(r?.data["cloudFolders"].slice(0, 1));
                }).catch(() => {
                    setProgress(false);
                }).finally(() => {
                    setSubLoading(false);
                });
            })()
        }
    };

    useEffect(() => {
        FtpSubFolderListAsync();
    }, [selectedSubContainer[0]?.folder_Id])

    const FtpSubFolderListAsync = () => {
        (async () => {
            if (selectedSubContainer[0]?.folder_Id !== undefined && isFolderExists > 0) {
                setFTPLoading(true);
                await useFetch<IFTPSubFolder[]>(`AuditLog/GetCloudSubFolders?folderId=${selectedSubContainer[0]?.folder_Id}&subFolderName=${searchSubFolderName}`).then((r) => {
                    setSubFId(r?.data[0]?.subFolder_Id);
                    setFTPSubContainers(r?.data);
                    setFTPsubFolderExpand(!r?.data[0]?.subFolder_Id ? false : true);
                    setSelectedftpSubContianer(r?.data?.slice(0, 1));
                }).finally(() => {
                    setFTPLoading(false);
                });
            }
        })().finally(() => {
        })
    }

    const exportFileAudit = (FileId: number) => {
        (async () => {
            let request = {
                "categoryId": SelectedContainer[0]?.categoryId,
                "fileId": FileId
            }
            await usePost<IFileExport[]>("AuditLog/ExportFileAudit", request).then((r) => {
                setExportAudit(r?.data);
            }).finally(() => {
                setisExport(true);
            })
        })()
    }

    const handleContainerToggle = (CNames: IContainerResult) => () => {
        let newSelectedContainers = [...SelectedContainer].filter(r => r?.containerName == r?.containerName[""]);

        if (newSelectedContainers?.some(c => c?.categoryId == CNames?.categoryId)) {
            newSelectedContainers = newSelectedContainers?.filter(c => c?.containerName != CNames?.containerName);
        } else {
            newSelectedContainers?.push(CNames);
        }
        setPage(0);
        setSelectedFile([]);
        setSelectedSubContianer([]);
        setSelectedftpSubContianer([]);
        setSelectedContianer(newSelectedContainers);
    };

    const handleSubContainerToggle = (subName: ISubFolder) => () => {
        let newSelectedContainers = [...selectedSubContainer]?.filter(r => r?.folder_Name == r?.folder_Name[""]);

        if (newSelectedContainers?.some(c => c?.folder_Id == subName?.folder_Id)) {
            newSelectedContainers = newSelectedContainers?.filter(c => c?.folder_Name != subName?.folder_Name);
        } else {
            newSelectedContainers?.push(subName);
        }
        setPage(0);
        setFId(subName?.folder_Id);
        setSubFId(0);
        setSelectedFile([]);
        setSelectedSubContianer(newSelectedContainers);
    };

    const handleftpSubFolderToggle = (subFtpName: IFTPSubFolder) => () => {
        let newSelectedContainers = [...selectedftpSubContainer]?.filter(r => r?.subFolder_Name == r?.subFolder_Name[""]);

        if (newSelectedContainers?.some(c => c?.subFolder_Id == subFtpName?.subFolder_Id)) {
            newSelectedContainers = newSelectedContainers?.filter(c => c?.subFolder_Name != subFtpName?.subFolder_Name);
        } else {
            newSelectedContainers?.push(subFtpName);
        }
        setPage(0);
        setSelectedFile([]);
        setSubFId(subFtpName.subFolder_Id);
        setSelectedftpSubContianer(newSelectedContainers);
    };

    const ListofContainers = () => (
        <React.Fragment>
            <List dense component="div" role="list" >
                {ListContainers.map((CName, index) => {
                    const labelId = `transfer-list-item-${index}-label`;
                    return (
                        <ListItem key={index} role="listitem" button onClick={handleContainerToggle(CName)} className={`${isExpanded ? classes.textpropertyOut : classes.textProperty} ${classes.listpadding1}`} disabled={progress}>
                            <ListItemIcon style={{ borderLeft: `4px solid ${CName.colorCode}` }} className={classes.folderColor}>
                                <GreenCheckbox
                                    id="FV_Checkbox"
                                    size="small"
                                    className={classes.CheckBoxStyle}
                                    checked={SelectedContainer.some(c => c?.categoryId == CName?.categoryId)}
                                    tabIndex={-1}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemIcon className={classes.fileNameStyle}>
                                <span>
                                    {SelectedContainer.some(c => c?.categoryId == CName?.categoryId) ? <FolderOpenIcon className={classes.listIconSize} style={{ color: '#FFC107' }} /> : <FolderIcon className={classes.listIconSize} style={{ color: "#FFC107" }} />}
                                </span>
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={<span className={classes.fileText}><b>{CName?.containerName}</b></span>} />
                        </ListItem>
                    );
                })
                }
                < ListItem />
            </List >
        </React.Fragment >
    );

    const ListofSubFolders = () => (
        <React.Fragment>
            <List style={{ padding: '0px' }}>
                {listSubContainers.map((subName, index) => {
                    const labelId = `transfer-list-item-${index}-label`;
                    return (
                        <ListItem key={index} role="listitem" button onClick={handleSubContainerToggle(subName)} className={`${subFolderExpand ? classes.textpropertyOut : classes.textProperty} ${classes.listpadding}`}>
                            <ListItemIcon style={{ borderLeft: `4px solid ${subName.color_Code}` }} className={classes.folderColor}>
                                <GreenCheckbox
                                    id="FV_Checkbox"
                                    size="small"
                                    className={classes.CheckBoxStyle}
                                    checked={selectedSubContainer?.some(c => c.folder_Id == subName.folder_Id)}
                                    tabIndex={-1}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemIcon className={classes.fileNameStyle}>
                                <span>
                                    {selectedSubContainer.some(c => c?.folder_Id == subName?.folder_Id) ? <FolderOpenIcon className={classes.listIconSize} style={{ color: '#FFC107' }} /> : <FolderIcon className={classes.listIconSize} style={{ color: "#FFC107" }} />}
                                </span>
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={<span className={classes.fileText}>{subName?.folder_Name}</span>} />
                        </ListItem>
                    );
                })
                }
                < ListItem />
            </List >
        </React.Fragment >
    );

    const ListofFTPSubFolders = () => (
        <React.Fragment>
            <List style={{ padding: '0px' }}>
                {ftpSubContainers.map((subftpName, index) => {
                    const labelId = `transfer-list-item-${index}-label`;
                    return (
                        <ListItem key={index} role="listitem" button disabled={fId === 0 ? true : false} onClick={handleftpSubFolderToggle(subftpName)} className={`${ftpsubFolderExpand ? classes.textpropertyOut : classes.textProperty} ${classes.listpadding}`}>
                            <ListItemIcon className={classes.FtpfolderStyle}>
                                <GreenCheckbox
                                    id="FV_Checkbox"
                                    size="small"
                                    className={classes.CheckBoxStyle}
                                    checked={selectedftpSubContainer.some(c => c?.subFolder_Id == subftpName?.subFolder_Id)}
                                    tabIndex={-1}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemIcon className={classes.fileNameStyle}>
                                <span>
                                    {selectedftpSubContainer.some(c => c?.subFolder_Id == subftpName?.subFolder_Id) ? <FolderOpenIcon className={classes.listIconSize} style={{ color: '#FFC107' }} /> : <FolderIcon className={classes.listIconSize} style={{ color: "#FFC107" }} />}
                                </span>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography
                                    variant="body2"
                                    fontSize="12px"
                                    className={`${searchSubFolderName !== "" && subftpName?.subFolder_Name?.toUpperCase().includes(searchSubFolderName?.toUpperCase())
                                        ? classes.searchTextHighlight
                                        : (subftpName?.parent_Id === 8 && subftpName?.subFolderCount > 0) ||
                                            (subftpName?.parent_Id === 9 && subftpName?.subFolderCount > 0)
                                            ? classes.subFolderCountText
                                            : classes.subFolderNormalText
                                        }`}
                                >
                                    {subftpName?.subFolder_Name}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    );
                })
                }
                < ListItem />
            </List >
        </React.Fragment >
    );

    // #Start region Get Files
    useEffect(() => {
        GetFilesAsync();
    }, [SelectedContainer[0]?.categoryId, fId, subFId, isArchive, triggerSearch, rowsPerPage, page, orderBy, order])

    const GetFilesAsync = () => {
        (async () => {
            if (SelectedContainer?.length !== 0 && fId !== 0 && subFId !== 0) {
                setProgress(true);
                let request = {
                    categoryId: (SelectedContainer[0]?.categoryId),
                    fileName: isSeached === true ? (!searchFile?.length ? null : searchFile) : null,
                    folderId: fId,
                    subFolderId: subFId,
                    limit: rowsPerPage,
                    start: (page * rowsPerPage) + 1,
                    activeFiles: isArchive ? 3 : fId === 8 ? 2 : fId === 9 ? 4 : 1,
                    orderBy: orderBy,
                    orderDir: order === 'desc' ? 1 : 0
                }
                await usePost<{ getFiles: IWebViewFiles[], total: number }>("AuditLog/GetWebViewFiles_v1", request).then((r) => {
                    setListWebViewFiles(r?.data["getFiles"]);
                    setTotalFiles(r?.data?.total);
                }).finally(() => {
                    setProgress(false);
                    setSelected(null);
                });

                {
                    SelectedContainer[0]?.categoryId === 5 ?
                        await useFetch<any>("File/BlobFileExists").then((r) => {
                            setisFileExists(r?.data?.files > 0 ? true : false);
                        })
                        :
                        null
                }
            }
        })()
    }

    useEffect(() => {
        (async () => {
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": "",
                "isAdmin": 1
            }
            await usePost<IClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setSubFolderNameList(r?.data);
            }).finally(() => {
            });
        })()
    }, [])

    const cancelSearch = () => {
        (async () => {
            if (fId !== 0) {
                let request = {
                    categoryId: (SelectedContainer[0]?.categoryId),
                    fileName: null,
                    folderId: fId,
                    subFolderId: subFId,
                    limit: rowsPerPage,
                    start: (page * rowsPerPage) + 1,
                    activeFiles: !isArchive ? 1 : 3,
                    orderBy: orderBy,
                    orderDir: order === 'desc' ? 1 : 0
                }
                setPage(0);
                setSelectedFile([]);
                setIsSearched(false);
                setProgress(true);
                await usePost<{ getFiles: IWebViewFiles[], total: number }>("AuditLog/GetWebViewFiles_v1", request).then((r) => {
                    setListWebViewFiles(r?.data["getFiles"]);
                    setTotalFiles(r?.data?.total);
                    setSearchFile("");
                }).finally(() => {
                    setProgress(false);
                });
            }
        })()
    };
    // #End region Get Files

    /* Future Use */
    const cancelSearchSubFolderName = () => {
        FtpSubFolderListAsync();
    };

    // #Start region Table Header
    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
            onSelectAllClick,
            rowCount, numSelected
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "fileName", numeric: false, disablePadding: false, label: "FILE NAME", sortable: true, width: '380px' },
            { id: "fileSize", numeric: false, disablePadding: false, label: "FILE SIZE", sortable: true },
            { id: "created_Date", numeric: false, disablePadding: false, label: "CREATED DATE", sortable: false },
            { id: "", numeric: false, disablePadding: false, label: "ACTIONS", sortable: false, marginLeft: '35px' },
        ];

        const PlacementHeadCells = [
            { id: "userName", numeric: false, disablePadding: false, label: "USER NAME", sortable: false, width: '60px' },
            { id: "originalFileName", numeric: false, disablePadding: false, label: "ORIGINAL FILENAME", sortable: false, width: '100px' },
            { id: "fileName", numeric: false, disablePadding: false, label: "FILE NAME", sortable: true, width: '170px' },
            { id: "fileSize", numeric: false, disablePadding: false, label: "FILE SIZE", sortable: true },
            { id: "created_Date", numeric: false, disablePadding: false, label: "CREATED DATE", sortable: false },
            { id: "", numeric: false, disablePadding: false, label: "ACTIONS", sortable: false, marginLeft: '35px' },
        ];

        /* Future Use */
        const ErrorHeadCells = [
            { id: "fileName", numeric: false, disablePadding: false, label: "FILE NAME", sortable: true },
            { id: "fileSize", numeric: false, disablePadding: false, label: "FILE SIZE", sortable: true },
            { id: "created_Date", numeric: false, disablePadding: false, label: "CREATED DATE", sortable: false },
            { id: "rejectedReason", numeric: false, disablePadding: false, label: "REJECTED REASON", sortable: false },
            { id: "", numeric: false, disablePadding: false, label: "ACTIONS", sortable: false, marginLeft: '35px' },
        ];

        return (
            <TableHead >
                <TableRow style={{ backgroundColor: 'blue' }}>
                    {state.userAccessContext?.role === 3 ?
                        <TableCell className={classes.thPadding} >
                            <GreenCheckbox
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                            />
                        </TableCell>
                        :
                        null
                    }
                    {SelectedContainer[0]?.categoryId != 5 && !ftpContainerWithMoveIcon && SelectedContainer[0]?.categoryId != PlacementRejectedFileID ?
                        headCells.map(headCell => (
                            <TableCell className={classes.rowhead}
                                style={{ marginLeft: `${headCell.marginLeft}` }}
                                width={headCell.width}
                                key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}>
                                    {headCell.label || headCell.sortable}
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === "desc" ? " " : " "}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        ))
                        : ftpContainerWithMoveIcon ?
                            ErrorHeadCells?.map(headCell => (
                                <TableCell className={classes.rowhead}
                                    style={{ marginLeft: `${headCell.marginLeft}` }}
                                    // width={headCell?.width}
                                    key={headCell.id} sortDirection={orderBy === headCell?.id ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === headCell?.id}
                                        direction={orderBy === headCell?.id ? order : "asc"}
                                        onClick={createSortHandler(headCell?.id)}>
                                        {headCell.label || headCell.sortable}
                                        {orderBy === headCell?.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === "desc" ? " " : " "}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))
                            : SelectedContainer[0]?.categoryId === PlacementRejectedFileID ?
                                PlacementHeadCells.map(headCell => (
                                    <TableCell className={classes.rowhead}
                                        style={{ marginLeft: `${headCell.marginLeft}` }}
                                        width={headCell.width}
                                        key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : "asc"}
                                            onClick={createSortHandler(headCell.id)}>
                                            {headCell.label || headCell.sortable}
                                            {orderBy === headCell.id ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === "desc" ? " " : " "}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))
                                :
                                headCells.slice(0, 3).map(headCell => (
                                    <TableCell className={classes.rowhead}
                                        style={{ marginLeft: `${headCell.marginLeft}` }}
                                        width={headCell.width}
                                        key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : "asc"}
                                            onClick={createSortHandler(headCell.id)}>
                                            {headCell.label || headCell.sortable}
                                            {orderBy === headCell.id ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === "desc" ? " " : " "}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))
                    }
                </TableRow>
            </TableHead >
        );
    };
    // #End region  Table Header

    // #Start region  Download MultiFile
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked === true) {
            let selectedfiled = [];
            listWebViewFiles.map((n) => selectedfiled.push({ file_Name: n?.fileName, bloburi: n?.blobUri, fileId: n?.fileId }));
            setSelectedFile(selectedfiled);
            return;
        }
        else {
            setSelectedFile([]);
        }
    };

    const handleFormData = (FileData: IWebViewFiles) => {
        let newSelectedFiles = [...selectedFile];
        if (newSelectedFiles.some(c => c?.fileId == FileData?.fileId)) {
            newSelectedFiles = newSelectedFiles.filter(c => c?.fileId != FileData?.fileId);
        } else {
            newSelectedFiles.push({ file_Name: FileData?.fileName, bloburi: FileData?.blobUri, fileId: FileData?.fileId });
        }
        setSelectedFile(newSelectedFiles);
    };

    const findDuplicateFiles = (response: IBlobFiles[]) => {
        let newFileExtension;
        var index = 1;
        let newFileName;
        for (var i = 0; i < response?.length; i++) {
            newFilesList?.map((r) => {
                if (r?.fileName?.includes(response[i].fileName)) {
                    newFileExtension = response[i].fileName?.split('.').pop();
                    newFileName = response[i].fileName?.substring(0, response[i].fileName?.lastIndexOf('.'));
                    newFileName = newFileName + " (" + index + ').' + newFileExtension;
                    let fileDetails: IBlobFiles = { fileName: newFileName, fileContent: response[i].fileContent }
                    newFilesList.push(fileDetails);
                    index++;
                } else {
                    if (newFilesList.findIndex(x => x.fileName == response[i].fileName) > -1) {
                        newFilesList.filter(x => x.fileName !== response[i].fileName);
                    } else {
                        let fileDetails: IBlobFiles = { fileName: response[i].fileName, fileContent: response[i].fileContent }
                        newFilesList.push(fileDetails);
                        index = 1;
                    }
                }
            })
            if (i === 0) {
                let fileDetails: IBlobFiles = { fileName: response[i].fileName, fileContent: response[i].fileContent }
                newFilesList.push(fileDetails);
            }
        }
    }

    const downloadMultipleFiles = () => {
        if (SelectedContainer[0]?.categoryId === PlacementRejectedFileID) {
            (async () => {
                setMulLoading(true);
                await usePost<IBlobFiles[]>(`File/DownloadPlacmentRejectedMultipleFiles`, selectedFile).then((response) => {
                    var zip = new JSZip();
                    findDuplicateFiles(response.data);
                    newFilesList?.map((f) => {
                        var folder = zip.folder(SelectedContainer[0]?.containerName);
                        folder.file(f.fileName, f.fileContent, { base64: true });
                    });

                    zip.generateAsync({ type: "blob" }).then(function (content) {
                        saveAs(content, !isArchive ? `${SelectedContainer[0]?.containerName}.zip` : `Archived-${SelectedContainer[0]?.containerName}.zip`);
                        setIsDownloaded(true);
                    });
                }).catch(() => {
                    setIsExists(true);
                }).finally(() => {
                    updateFile(true);
                    setMulLoading(false);
                    setSelectedFile([]);
                    newFilesList = [];
                });
            })()
        } else {
            (async () => {
                setMulLoading(true);
                await usePost<IBlobFiles[]>(`File/DownloadMultipleFiles`, selectedFile).then((response) => {
                    var zip = new JSZip();
                    findDuplicateFiles(response.data);
                    newFilesList?.map((f) => {
                        if (subFId !== 0 && selectedftpSubContainer?.length) {
                            var folder = !isArchive ? zip.folder(SelectedContainer[0]?.containerName) : zip.folder(`Archived-${SelectedContainer[0]?.containerName}`);
                            var subfolder = folder.folder(selectedSubContainer[0]?.folder_Name);
                            var FtpFolder = subfolder.folder(selectedftpSubContainer[0]?.subFolder_Name)
                            FtpFolder.file(f.fileName, f.fileContent, { base64: true });
                        }
                        else if (subFId !== 0 && !selectedftpSubContainer?.length) {
                            var folder = !isArchive ? zip.folder(SelectedContainer[0]?.containerName) : zip.folder(`Archived-${SelectedContainer[0]?.containerName}`);
                            var subfolder = folder.folder(selectedSubContainer[0]?.folder_Name);
                            subfolder.file(f.fileName, f.fileContent, { base64: true });
                        }
                        else {
                            var folder = !isArchive ? zip.folder(SelectedContainer[0]?.containerName) : zip.folder(`Archived-${SelectedContainer[0]?.containerName}`);
                            folder.file(f.fileName, f.fileContent, { base64: true });
                        }
                    });

                    zip.generateAsync({ type: "blob" }).then(function (content) {
                        saveAs(content, !isArchive ? `${SelectedContainer[0]?.containerName}.zip` : `Archived-${SelectedContainer[0]?.containerName}.zip`);
                        setIsDownloaded(true);
                    });
                }).catch(() => {
                    setIsExists(true);
                }).finally(() => {
                    updateFile(true);
                    setMulLoading(false);
                    setSelectedFile([]);
                    newFilesList = [];
                });
            })()
        }
    };
    // #End region  Download MultiFile

    // #Start region DownloadFile
    const HandleDownload = (blobUri: string, FileName: string, FileId: number) => {
        (async () => {
            let request = {
                "blobUri": blobUri
            };

            let GetFileID: IFileDetails[] = [];
            let FileDetails: IFileDetails = { fileDetails: FileId };
            GetFileID.push(FileDetails);

            let requestArchive = {
                "fileId": _.cloneDeep(GetFileID),
                "categoryId": SelectedContainer[0]?.categoryId,
                "fileStatus": 3,
                "actionId": 4,
                "userId": state?.userAccessContext?.id,
                "modifiedDate": new Date()
            }

            setDownloadPro(true);
            await usePost<any>("File/GetBlobFileContent", request).then(async (r) => {
                const arrayBuffer = base64ToArrayBuffer(r.data);
                createAndDownloadBlobFile(arrayBuffer, FileName);
                setIsDownloaded(true);
                await usePost<any>("AuditLog/UpdateFileStatus", requestArchive);
            }).catch(() => {
                setIsExists(true);
            }).finally(async () => {
                setDownloadPro(false);
                GetFilesAsync();
            });
        })()
    }

    const DownloadPdfFileAsync = (blobUri: string, FileName: string, FileId: number) => {
        (async () => {
            let request = {
                "blobUri": blobUri
            };

            let requestDownload = {
                "fileId": FileId,
                "actionId": trustStatementAction?.FileDownloaded,
                "userId": state?.userAccessContext?.id
            }

            setDownloadPro(true);
            await usePost<any>("File/TrustStatementPDFFileDownload", request).then(async (r) => {
                const arrayBuffer = base64ToArrayBuffer(r.data);
                createAndDownloadBlobFile(arrayBuffer, FileName);
                setIsDownloaded(true);
                if (SelectedContainer[0]?.categoryId === 7) {
                    await usePost<any>("TrustStatementAuditHistory", requestDownload);
                }
            }).catch(() => {
                setIsExists(true);
            }).finally(() => {
                setDownloadPro(false);
                GetFilesAsync();
            });
        })()
    }

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    };

    function createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    // #End region DownloadFile

    // #Start region Pagination
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setSelectedFile([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
    };
    // #End region Pagination

    // #Start region SearchFile
    const initiateSearch = () => {
        setPage(0);
        setSelectedFile([]);
        setTriggerSearch(!triggerSearch);
    };
    // #End region SearchFile

    // #Start region SearchFile
    const initiateSearchSubFolder = () => {
        FtpSubFolderListAsync();
    };
    // #End region SearchFile

    //MassFileUpload
    const updateFile = (isArchive: boolean) => {
        (async () => {
            let GetFileID: IFileDetails[] = [];
            if (selectedFile.length <= 0) {
                let FileDetails: IFileDetails = { fileDetails: newFileID };
                GetFileID.push(FileDetails);
            } else {
                selectedFile.map((x) => {
                    let FileDetails: IFileDetails = { fileDetails: x?.fileId };
                    GetFileID.push(FileDetails);
                });
            }
            let request = {
                "fileId": _.cloneDeep(GetFileID),
                "categoryId": SelectedContainer[0]?.categoryId,
                "fileStatus": 3,
                "actionId": (isArchive === true ? 4 : 3),
                "userId": state?.userAccessContext?.id,
                "modifiedDate": new Date()
            }
            await usePost<any>("AuditLog/UpdateFileStatus", request);
        })().finally(() => {
            GetFilesAsync();
            setSelectedFile([]);
        });
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let arrayFiles = Array.from(event.currentTarget.files)

        let ValidFiles: File[] = (!acceptedFiles?.length) ? [] : _.cloneDeep(acceptedFiles);
        let RejectedFile: File[] = (!rejectedFiles?.length) ? [] : _.cloneDeep(rejectedFiles);
        arrayFiles.map((r) => {
            if (FileNameVal.test(r.name)) {
                if (ValidFiles?.findIndex((v) => (v.name === r.name)) <= -1) {
                    ValidFiles.push(r);
                }
            } else {
                if (RejectedFile?.findIndex((v) => (v.name === r.name)) <= -1) {
                    RejectedFile.push(r);
                }
            }
        })
        setAcceptedFiles(ValidFiles);
        setRejectedFiles(RejectedFile);
        event.currentTarget.value = null;
    }

    const UploadMultipleFile = (files: File[]) => {
        (async () => {
            setFileLoading(true);
            handleCloseUpload();
            const formData = new FormData();
            files.map((f) => {
                formData.append('multiFiles', f);
                formData.append("CategoryId", SelectedContainer[0]?.categoryId.toString());
                formData.append("Userid", state.userAccessContext?.id.toString());
            });
            await usePost<any>("WebViewFiles/UploadMultipleFiles", formData).finally(() => {
                setFileLoading(false);
            }).finally(() => {
                GetFilesAsync();
            });
        })()
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    const handleOpenTrigger = () => {
        setIsTriggerFile(true);
    };
    const handleCloseTrigger = () => {
        setIsTriggerFile(false);
    };

    const handleAlertClose = () => {
        setOpenDialogBox(false);
    };

    const handleProgressClose = () => {
        setOpenTimeLine(false);
        setIsTriggerFile(false);
    }

    const handleGatheringFiles = () => {
        if (!VerifyFiles) {
            setSuccess(false);
            setVerifyFiles(true);
            timer.current = window.setTimeout(() => {
                setSuccess(true);
                setVerifyFiles(false);
                handleUploadFiles();
            }, 2000);
        }
    }

    const handleUploadFiles = () => {
        if (!UploadMassFiles) {
            setSuccess(false);
            setUploadMassFiles(true);
            timer.current = window.setTimeout(() => {
                setSuccess(true);
                setUploadMassFiles(false);
            }, 2000);
        }
    }

    const uploadMassFiles = () => {
        (async () => {
            await axios.post(`${MassFileTrigger}/${state?.userAccessContext?.id}/${apiKey}?`).then((r) => {
                if (r.status === 200) {
                    setIsTriggerFile(false);
                    setOpenTimeLine(false);
                    setisFileExists(false);
                    setOpenDialogBox(true);
                    setMessage("Files are uploaded successfully");
                }
            }).catch((err) => {
                if (err.response.status == 500 || err.response.status == 400) {
                    setIsTriggerFile(false);
                    setOpenTimeLine(false);
                    setOpenDialogBox(true);
                    setMessage("Error while uploading files, verify that all files are valid.");
                }
            });
        })()
    }

    const HandleCheckValidUser = () => {
        (async () => {
            if (state.userAccessContext?.id !== undefined) {
                await useFetch<IUserInvited[]>(`User/CheckUserInvited?userId=${state.userAccessContext?.id}`).then((r) => {
                    setIsValid(r.data[0]?.user_id !== undefined && r.data[0]?.user_id !== 0 ? true : false);
                    setOpenTimeLine(true);
                    if (!UserVerify) {
                        setSuccess(false);
                        setUserVerify(true);
                        timer.current = window.setTimeout(() => {
                            setSuccess(true);
                            setUserVerify(false);
                            if (r.data[0]?.user_id !== undefined && r.data[0]?.user_id !== 0) {
                                uploadMassFiles();
                                handleGatheringFiles();
                            }
                        }, 2000);
                    }
                })
            };
        })()
    }

    const handleTrustStatementUnArchieveFile = (fileData: IWebViewFiles) => {
        (async () => {
            let request = {
                "fileId": fileData?.fileId,
                "actionId": trustStatementAction?.FileUnarchieved,
                "userId": state?.userAccessContext?.id,
                "errorId": null
            }
            await usePost<any>("TrustStatementAuditHistory", request);
        })().finally(() => {
            GetFilesAsync();
        })
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex' }}>
                <div className={`${isExpanded ? classes.sidebar : classes.sidebarCollapsed}`}>
                    <div className={classes.sidebarHeader}>
                        <IconButton size="small" onClick={handleMainFolderToggler} className={classes.iconPosition}>
                            {
                                isExpanded ?
                                    <DoubleArrowIcon fontSize="small" style={{ color: 'black', transform: 'rotateY(180deg)' }} />
                                    :
                                    <DoubleArrowIcon fontSize="small" style={{ color: 'black' }} />
                            }
                        </IconButton>
                        <Typography className={`${isExpanded ? classes.MainFolderTitle : classes.MainFolderTitleHidden}`} id="tableTitle">
                            WEBVIEW FOLDERS
                        </Typography>
                    </div>
                    {loading === true ? <LinearProgress /> : null}
                    <Scrollbars autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={10}
                        autoHeight
                        autoHeightMin={500}
                        autoHeightMax={500}
                        style={{ width: '100%' }}>
                        {ListofContainers()}
                    </Scrollbars>
                </div>

                <Grid xs={12}>
                    <Toolbar className={classes.ToolbarStyle} style={{ backgroundColor: `${!isArchive ? "white" : "#6c757d"}` }}>
                        <Box p={0} alignContent="flex-start" justifyContent="flex-start" alignItems="flex-start" width="18%" style={{ borderLeft: `4px solid ${SelectedContainer[0]?.colorCode}`, borderRadius: '7px', paddingLeft: "4px" }}>
                            <Typography className={`${isExpanded ? classes.folderTitleExtended : classes.folderTitle}`} id="tableTitle"
                                style={{ color: `${!isArchive ? "black" : "white"}` }} >
                                {SelectedContainer[0]?.containerName?.toUpperCase()}
                            </Typography>
                            <Typography className={classes.folderSubTitle} variant="subtitle2" id="tableTitle"
                                style={{ color: `${!isArchive ? "black" : "white"}` }}>
                                {SelectedContainer[0]?.containerDescription?.toLowerCase()}
                            </Typography>
                        </Box>

                        <Box display="flex" p={0} alignContent="center" justifyContent="center" width="68%" className={classes.PrimaryboxGap}>
                            <TextField value={searchFile} variant="outlined"
                                type='text' placeholder="Search Files"
                                className={classes.searchBox}
                                style={{ color: `${!isArchive ? "black" : "white"}`, borderColor: `${!isArchive ? "black" : "white"}` }}
                                onChange={e => setSearchFile(e.target.value.trim())}
                                onKeyPress={event => {
                                    if (event.key === 'Enter' && searchFile != "") {
                                        initiateSearch(); setShowSearchIcon(false); setIsSearched(true);
                                    } else if (event.key === 'Enter' && searchFile === "") {
                                        initiateSearch(); setShowSearchIcon(true); setIsSearched(false);
                                    }
                                }}
                                inputProps={{
                                    maxlength: 255,
                                    className: `${!isArchive ? classes.input : classes.inputArchive}`
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.outlinedInput,
                                        notchedOutline: classes.notchedOutline,
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {showSearchIcon === true ?
                                                <IconButton onClick={e => { initiateSearch(); setShowSearchIcon(false); setIsSearched(true); }}>
                                                    <SearchIcon style={{ alignItems: 'center', color: `${!isArchive ? "black" : "white"}` }} />
                                                </IconButton>
                                                :
                                                <IconButton onClick={e => { cancelSearch(); }}>
                                                    <CloseIcon style={{ alignItems: 'center', color: `${!isArchive ? "black" : "white"}` }} />
                                                </IconButton>
                                            }
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        {SelectedContainer[0]?.categoryId === 5 && !isArchive ?
                            <Box display="flex">
                                <AvatarTooltip title="Upload File" >
                                    <img src={upload} alt="Icon for Download" width="35px" height="35px" onClick={() => { setUploadFiles(true); }} />
                                </AvatarTooltip>
                            </Box>
                            :
                            null
                        }
                        <Box display="flex" alignContent="center" justifyContent="center" alignItems="center" width="5%">
                            {SelectedContainer[0]?.categoryId === PlacementRejectedFileID ? null :
                                <LightTooltip title="Archive Files">
                                    {selectedFile?.length > 0 && !isArchive ?
                                        <img src={fileArchive} alt="Icon for Download" width="35px" height="35px" onClick={() => { setMultiArchive(true); }} />
                                        :
                                        <img src={fileArchiveDisable} alt="Icon for Download" width="35px" height="35px" />
                                    }
                                </LightTooltip>
                            }
                        </Box>
                        <Box display="flex" alignContent="center" justifyContent="center" alignItems="center" width="5%">
                            <LightTooltip title="Download File">
                                {selectedFile?.length > 0 ?
                                    <img src={download} alt="Icon for Download" width="32px" height="30px" onClick={(_) => { setMultiDownload(true); }} />
                                    :
                                    <img src={downloadDisable} alt="Icon for Download" width="32px" height="30px" />
                                }
                            </LightTooltip>
                        </Box>

                        <Box display="flex" alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end" width="5%" className={classes.SecondaryBoxGap}>
                            <LightTooltip title="Switch to view Archive Files">
                                <FormControlLabel
                                    labelPlacement="top"
                                    control={<ArchiveSwitch onChange={(_) => { setIsArchive(s => !s); setSelectedFile([]); }} />}
                                    label=""
                                />
                            </LightTooltip>
                        </Box>
                    </Toolbar>

                    <div style={{ display: 'flex' }}>
                        {isFolderExists > 0 ?
                            <div className={`${subFolderExpand ? classes.subSidebar : classes.subSidebarCollapsed}`}>
                                <div className={classes.sidebarHeader}>
                                    <IconButton size="small" onClick={handleSubFolderToggler} className={classes.iconPosition}>
                                        {
                                            subFolderExpand ?
                                                <DoubleArrowIcon fontSize="inherit" style={{ color: 'black', transform: 'rotateY(180deg)' }} />
                                                :
                                                <DoubleArrowIcon fontSize="inherit" style={{ color: 'black' }} />
                                        }
                                    </IconButton>
                                    <Typography className={`${subFolderExpand ? classes.MainFolderTitle : classes.MainFolderTitleHidden}`} id="tableTitle">
                                        {SelectedContainer[0]?.folderName?.toUpperCase()}
                                    </Typography>
                                </div>
                                {subLoading === true ? <LinearProgress /> : null}
                                <Scrollbars autoHide
                                    autoHideTimeout={1000}
                                    autoHideDuration={10}
                                    autoHeight
                                    autoHeightMax={480}
                                    style={{ width: "100%" }}>
                                    {ListofSubFolders()}
                                </Scrollbars>
                            </div>
                            : null
                        }

                        <div className={classes.divFiles}>
                            {(downloadPro === true || mulLoding === true || fileLoading === true) ? <LinearProgress /> : null}
                            <div style={{ display: 'flex' }} >
                                <div className={classes.breadCrumsDivStyle}>
                                    <BreadCrumbs MainFolder={SelectedContainer[0]?.containerName} subFolder={selectedSubContainer[0]?.folder_Name} FTPFolder={selectedftpSubContainer[0]?.subFolder_Name} />
                                </div>
                                <div className={classes.debtorfileDiv}>
                                    {SelectedContainer[0]?.categoryId === 5 && IsFileExists === true && !fileLoading ?
                                        <Button size="small" variant='contained' onClick={handleOpenTrigger} startIcon={<CloudUploadIcon />} color="primary" className={classes.massuploadButton}>
                                            Mass Upload
                                        </Button>
                                        :
                                        null
                                    }
                                </div>
                            </div>

                            <div className={classes.ftpDiv}>
                                {isFolderExists > 0 && ftpSubContainers?.length > 0 ?
                                    <div className={`${ftpsubFolderExpand ? classes.ftpsubSidebar : classes.ftpsubSidebarCollapsed}`}>
                                        <div className={classes.sidebarHeader}>
                                            <IconButton size="small" onClick={handleFTPSubFolderToggler} className={classes.iconPosition}>
                                                {
                                                    ftpsubFolderExpand ?
                                                        <DoubleArrowIcon fontSize="inherit" style={{ color: 'black', transform: 'rotateY(180deg)' }} />
                                                        :
                                                        <DoubleArrowIcon fontSize="inherit" style={{ color: 'black' }} />
                                                }
                                            </IconButton>
                                            <Typography className={`${ftpsubFolderExpand ? classes.MainFolderTitle : classes.MainFolderTitleHidden}`} id="tableTitle">
                                                FOLDERS
                                            </Typography>
                                        </div>
                                        <Box display="flex" p={1} alignContent="flex-start" justifyContent="flex-start" width="100%" className={classes.PrimaryboxGap}>
                                            <TextField
                                                value={searchSubFolderName}
                                                variant="outlined"
                                                type='text'
                                                placeholder="Search"
                                                className={`${ftpsubFolderExpand ? classes.searchBox2 : classes.searchboxHide}`}
                                                onChange={e => setSearchSubFolderName(e.target.value.trim())}
                                                onKeyPress={event => {
                                                    if (event.key === 'Enter' && searchSubFolderName !== "") {
                                                        initiateSearchSubFolder();
                                                        setShowSearchSubFolderIcon(false);
                                                        setIsSeachedSubFolder(true);
                                                    } else if (event.key === 'Enter' && searchSubFolderName === "") {
                                                        initiateSearchSubFolder();
                                                        setShowSearchSubFolderIcon(true);
                                                        setIsSeachedSubFolder(false);
                                                    }
                                                }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.ftpoutlinedInput,
                                                        notchedOutline: classes.ftpnotchedOutline,
                                                    },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {showSearchSunFolderIcon === true ?
                                                                <IconButton onClick={() => { initiateSearchSubFolder(); setShowSearchSubFolderIcon(false); setIsSeachedSubFolder(true); }}>
                                                                    <SearchIcon style={{ alignItems: 'center', fontSize: 17 }} />
                                                                </IconButton>
                                                                :
                                                                <IconButton onClick={() => { initiateSearchSubFolder(); setShowSearchSubFolderIcon(true); setIsSeachedSubFolder(false); }}>
                                                                    <CloseIcon style={{ alignItems: 'center', fontSize: 17 }} />
                                                                </IconButton>
                                                            }
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Box >
                                        {ftpLoading === true ? <LinearProgress /> : null}
                                        <Scrollbars autoHide
                                            autoHideTimeout={1000}
                                            autoHideDuration={10}
                                            autoHeight
                                            autoHeightMax={435}>
                                            {ListofFTPSubFolders()}
                                        </Scrollbars>
                                    </div >
                                    :
                                    null
                                }
                                <Box m={0} width="100%" display="flex" style={{ flexDirection: "column" }}>
                                    <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`} >
                                        {progress === true ?
                                            <React.Fragment>
                                                <Box display="flex" alignItems="center" justifyContent="center" width="100%" className={classes.spinnerStyle}>
                                                    <div className="loader" />
                                                </Box>
                                                <span>Loading Files</span>
                                            </React.Fragment>
                                            :
                                            <Table aria-label="collapsible table" size="small" stickyHeader >
                                                <EnhancedTableHead
                                                    classes={classes}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    onSelectAllClick={handleSelectAllClick}
                                                    numSelected={selectedFile?.length}
                                                    rowCount={listWebViewFiles?.length}
                                                />
                                                <TableBody>
                                                    {listWebViewFiles.map((row) => {
                                                        return (
                                                            <React.Fragment>
                                                                <TableRow>
                                                                    <TableCell className={classes.rowpadding}>
                                                                        <GreenCheckbox
                                                                            size="small"
                                                                            id="SA_Folder_Checkbox"
                                                                            checked={selectedFile.some(c => c?.fileId === row?.fileId)}
                                                                            tabIndex={-1}
                                                                            onClick={() => { handleFormData(row) }}
                                                                            disableRipple
                                                                        />
                                                                    </TableCell>
                                                                    {SelectedContainer[0]?.categoryId === PlacementRejectedFileID ?
                                                                        <TableCell className={classes.rowpadding}>
                                                                            <span className={`${searchFile === "" ? classes.NormalText : row?.userName === null ? null : row?.userName?.toUpperCase().includes(searchFile.toUpperCase()) ? classes.TextHighlight : classes.NormalText}`}>
                                                                                {row?.userName}
                                                                            </span>
                                                                        </TableCell>
                                                                        : null
                                                                    }
                                                                    {SelectedContainer[0]?.categoryId === PlacementRejectedFileID ?
                                                                        <TableCell className={classes.rowpadding}>
                                                                            <span className={`${searchFile === "" ? classes.NormalText : row?.originalFileName === null ? null : row?.originalFileName?.toUpperCase().includes(searchFile.toUpperCase()) ? classes.TextHighlight : classes.NormalText}`}>
                                                                                {row?.originalFileName}
                                                                            </span>
                                                                        </TableCell>
                                                                        : null
                                                                    }
                                                                    <TableCell className={classes.rowpadding}>
                                                                        <TooltipSmall title={row?.fileName}>
                                                                            <span className={`${searchFile === "" ? classes.NormalText1 : row?.fileName === null ? null : row?.fileName?.toUpperCase().includes(searchFile.toUpperCase()) ? classes.TextHighlight1 : classes.NormalText1}`}>
                                                                                {row?.fileName}
                                                                            </span>
                                                                        </TooltipSmall>
                                                                    </TableCell>
                                                                    <TableCell className={classes.rowpadding}>
                                                                        {row?.fileSize}
                                                                    </TableCell>
                                                                    <TableCell className={classes.rowpadding}>
                                                                        {`${moment(row?.created_Date).format("MM/DD/YYYY")} ${moment(row?.created_Date).format("HH:mm:ss")}`}
                                                                    </TableCell>

                                                                    {ftpContainerWithMoveIcon ?
                                                                        <TableCell className={classes.rowpadding}>
                                                                            <TooltipSmall title={row?.errorName}>
                                                                                <Typography variant='body2' className={classes.errorStyle}>
                                                                                    {row?.errorName}
                                                                                </Typography>
                                                                            </TooltipSmall>
                                                                        </TableCell>
                                                                        : null
                                                                    }

                                                                    {SelectedContainer[0]?.categoryId === 5 ? null :
                                                                        <TableCell className={classes.rowpadding} style={{ marginRight: '0px' }}>
                                                                            <TooltipSmall title="File Download">
                                                                                <IconButton onClick={() => { SelectedContainer[0]?.categoryId != 7 && SelectedContainer[0]?.categoryId != PlacementRejectedFileID ? HandleDownload(row?.blobUri, row?.fileName, row?.fileId) : DownloadPdfFileAsync(row?.blobUri, row?.fileName, row?.fileId) }} className={classes.actionIcon} disabled={selectedFile?.length > 1} >
                                                                                    <FileDownload fontSize='small' style={{ color: '#637381' }} />
                                                                                </IconButton>
                                                                            </TooltipSmall>
                                                                            {SelectedContainer[0]?.categoryId === 7 ?
                                                                                <TooltipSmall title="Preview File">
                                                                                    <DocumentPDFViewer row={row} enable={row?.fileName?.split('.')?.pop() === 'pdf' ? true : row?.fileName?.split('.')?.pop() === 'PDF' ? true : false} />
                                                                                </TooltipSmall>
                                                                                : null
                                                                            }
                                                                            {SelectedContainer[0]?.categoryId === PlacementRejectedFileID ? null
                                                                                :
                                                                                <TooltipSmall title="View Audit Log">
                                                                                    <IconButton onClick={() => { handleRowClick(row?.fileId); }} className={classes.actionIcon}>
                                                                                        <ViewAuditLog fontSize='small' style={{ color: '#229A16' }} />
                                                                                    </IconButton>
                                                                                </TooltipSmall>
                                                                            }

                                                                            {!isArchive && SelectedContainer[0]?.categoryId != PlacementRejectedFileID ?
                                                                                <TooltipSmall title="Archive File">
                                                                                    <IconButton onClick={() => { SelectedContainer[0]?.categoryId != 7 ? handleArchiveFile(row?.fileId) : handleTrustStatementArchieveFile(row) }} disabled={isArchive || selectedFile?.length > 1} className={classes.actionIcon}>
                                                                                        <ArchiveFile fontSize='small' style={{ color: '#f77f00' }} />
                                                                                    </IconButton>
                                                                                </TooltipSmall>
                                                                                : SelectedContainer[0]?.categoryId === 7 ?
                                                                                    <IconButton size='small' onClick={() => { handleTrustStatementUnArchieveFile(row); }}>
                                                                                        <TooltipSmall title="Unarchive File">
                                                                                            <UnarchiveIcon fontSize='small' style={{ color: '#f77f00' }} />
                                                                                        </TooltipSmall>
                                                                                    </IconButton>
                                                                                    : null
                                                                            }
                                                                            {SelectedContainer[0]?.categoryId != PlacementRejectedFileID ?
                                                                                <TooltipSmall title="Export Log">
                                                                                    <IconButton className={classes.actionIcon} onClick={() => { exportFileAudit(row?.fileId); setFileName(row?.fileName) }} disabled={selectedFile?.length > 1}>
                                                                                        <ExportLog fontSize='small' style={{ color: '#219ebc' }} />
                                                                                    </IconButton>
                                                                                </TooltipSmall>
                                                                                : null
                                                                            }
                                                                            {ftpContainerWithMoveIcon && !isArchive && row?.errorId !== ErrorReason?.FileCorrupted ? (
                                                                                <MoveFileErrorToProcessFolder fileDetails={row} subFolderNameList={subFolderNameList} onFetchDetails={GetFilesAsync} />
                                                                            ) : null}
                                                                        </TableCell>
                                                                    }
                                                                </TableRow>
                                                                <FileLogRequest row={row} selected={selected === row?.fileId} onClick={handleRowClick} categoryId={SelectedContainer[0]?.categoryId} />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    }
                                                </TableBody>
                                            </Table>
                                        }

                                        {!listWebViewFiles?.length && !progress ?
                                            <>
                                                <div className={classes.noRecordsColor}>
                                                    <img alt="noRecords" src={norecord} width="80px" height="80px" />
                                                </div>
                                                <Typography variant='h6' className={classes.noRecord}>
                                                    No files to display
                                                </Typography>
                                            </>
                                            : null}
                                    </TableContainer>

                                    <Box m={0} width="100%" display="flex" >
                                        <Box m={0} width="46%">
                                            <div className="footer">
                                                <Footer />
                                            </div>
                                        </Box>
                                        <Box m={0} width="54%">
                                            <div className="pagination">
                                                <TablePagination
                                                    id="Files_TablePagination"
                                                    rowsPerPageOptions={[20, 50, 100]}
                                                    component="div"
                                                    count={totalFiles === undefined ? 0 : totalFiles}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </div>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </div >
                    </div >
                </Grid >
            </div >

            <Dialog TransitionComponent={Transition} open={open} keepMounted
                className={classes.dialogbox}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        border: "1px solid #0D324D",
                        borderRadius: 15,
                        boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
                    }
                }}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleStyle}>
                        ARCHIVE FILE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent style={{ padding: '0px' }}>
                    <DialogTitle className={classes.titleHeader}>
                        <img src={archivefile} alt="Block_Email" className={classes.Image} />
                    </DialogTitle>
                    <Typography variant="h6" gutterBottom className={classes.textfield1}>
                        Are you sure, you want to archive this file?
                    </Typography>
                </DialogContent>

                <Box display="flex" alignItems="center" justifyContent="center" className={classes.dialogaction}>
                    <Button id="IM_ok_btn" size="small" startIcon={<CheckCircleIcon />} variant="contained" className={classes.yesButton} onClick={() => { updateFile(false); handleClose(); }} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" startIcon={<CancelIcon />} variant="contained" autoFocus className={classes.noButton} onClick={() => { handleClose(); }} color="primary">
                        No
                    </Button>
                </Box>
            </Dialog>

            <Dialog TransitionComponent={Transition} open={multiArchive} keepMounted
                className={classes.dialogbox}
                onClose={handleCloseDialog}
                PaperProps={{
                    style: {
                        border: "1px solid #0D324D",
                        borderRadius: 15,
                        boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
                    }
                }}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialog}>
                    <Typography variant="h5" gutterBottom className={classes.titleStyle}>
                        ARCHIVE FILES
                    </Typography>
                </DialogTitleHeader>
                <DialogContent style={{ padding: '0px' }}>
                    <DialogTitle className={classes.titleHeader}>
                        <img src={archivefile} alt="Block_Email" className={classes.Image} />
                    </DialogTitle>
                    <Typography variant="h6" gutterBottom className={classes.textfield1}>
                        Are you sure, you want to archive this {selectedFile?.length} files?
                    </Typography>
                </DialogContent>

                <Box display="flex" alignItems="center" justifyContent="center" className={classes.dialogaction}>
                    <Button id="IM_ok_btn" size="small" startIcon={<CheckCircleIcon />} variant="contained" className={classes.yesButton} onClick={() => { updateFile(false); handleCloseDialog(); }} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" startIcon={<CancelIcon />} variant="contained" autoFocus className={classes.noButton} onClick={() => { handleCloseDialog(); }} color="primary">
                        No
                    </Button>
                </Box>
            </Dialog>

            <Dialog TransitionComponent={Transition} open={multiDownload} keepMounted
                className={classes.dialogbox}
                onClose={handleCloseMultiDownload}
                PaperProps={{
                    style: {
                        border: "1px solid #0D324D",
                        borderRadius: 15,
                        boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
                    }
                }}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseMultiDownload}>
                    <Typography variant="h5" gutterBottom className={classes.titleStyle}>
                        DOWNLOAD FILES
                    </Typography>
                </DialogTitleHeader>
                <DialogContent style={{ padding: '0px' }}>
                    <DialogTitle className={classes.titleHeader}>
                        <img src={downloadFiles} alt="Block_Email" className={classes.image2} />
                    </DialogTitle>
                    <Typography variant="h6" gutterBottom className={classes.textfield1}>
                        Are you sure, you want to Download {selectedFile?.length} files?
                    </Typography>
                </DialogContent>

                <Box display="flex" alignItems="center" justifyContent="center" className={classes.dialogaction}>
                    <Button id="IM_ok_btn" size="small" startIcon={<CheckCircleIcon />} variant="contained" className={classes.yesButton} onClick={() => { downloadMultipleFiles(); handleCloseMultiDownload(); }} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" startIcon={<CancelIcon />} variant="contained" autoFocus className={classes.noButton} onClick={() => { handleCloseMultiDownload(); }} color="primary">
                        No
                    </Button>
                </Box>
            </Dialog>

            <Dialog TransitionComponent={Transition} open={uploadFiles} keepMounted
                PaperProps={{
                    style: {
                        borderRadius: 15,
                    }
                }}
                maxWidth={'md'}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseUpload}>
                    <Typography variant="h5" gutterBottom className={classes.titleStyle}>
                        UPLOAD FILE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent style={{ padding: '5px' }}>
                    <div style={{ padding: '2px 8px' }}>
                        <AlertMessage severity="warning" className="snackBarStyle1"><b>Please Upload .pdf files only. </b></AlertMessage>
                    </div>
                    <div className={classes.browseFileDiv}>
                        <Box alignItems="center" display="flex" justifyContent="center" >
                            <Box className={classes.root2} width="100%" alignItems="center" display="flex" justifyContent="center">
                                <IconButton aria-label="delete" disabled={true} style={{ color: "black" }}>
                                    <img src={upload} alt="Icon for Download" width="28px" height="28px" />
                                </IconButton>
                                <Typography className={classes.textStyle} variant="button" display="block" gutterBottom >
                                    Select a file
                                </Typography>
                                <ul className={classes.ulstyle}>
                                    <form encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
                                        <Button size="small" className={classes.fileUploadButton} component="label" color="primary"
                                            variant="contained" startIcon={<CloudUploadIcon />}>
                                            Browse File
                                            <input
                                                id="NF_SelectFile"
                                                onChange={(files) => { changeHandler(files) }}
                                                name="file"
                                                ref={fileUpload}
                                                hidden
                                                multiple type="file"
                                            />
                                        </Button>
                                    </form>
                                </ul>
                            </Box>
                        </Box>
                    </div>

                    <div className={classes.browseFileDiv}>
                        <Box width="100%" style={{ padding: '10px' }}>
                            {acceptedFiles?.length > 0 ?
                                <React.Fragment>
                                    <span className={classes.TextColorGreen}>Accepted Files : {acceptedFiles?.length}</span>
                                    <Paper className={classes.fileChip}>
                                        <Scrollbars autoHide
                                            autoHideTimeout={1000}
                                            autoHideDuration={10}
                                            autoHeight
                                            autoHeightMax={100}
                                            style={{ width: "100%" }}>
                                            {acceptedFiles.map((r) => {
                                                return (
                                                    <Chip
                                                        size="small"
                                                        label={r?.name}
                                                        clickable={false}
                                                        className={classes.accFiles}
                                                        deleteIcon={<HighlightOffIcon />}
                                                    />
                                                )
                                            })}
                                        </Scrollbars>
                                    </Paper>
                                </React.Fragment>
                                : null
                            }

                            {rejectedFiles?.length > 0 ?
                                <React.Fragment>
                                    <div className={classes.rejBoxPadding}>
                                        <span className={classes.TextColorRed}>Rejected Files : {rejectedFiles?.length}</span>
                                        <Paper className={classes.invalidFileChip}>
                                            <Scrollbars autoHide
                                                autoHideTimeout={1000}
                                                autoHideDuration={10}
                                                autoHeight
                                                autoHeightMax={100}
                                                style={{ width: "100%" }}>
                                                {rejectedFiles.map((r) => {
                                                    return (
                                                        <Chip
                                                            size="small"
                                                            label={r?.name}
                                                            clickable={false}
                                                            className={classes.regFiles}
                                                        />
                                                    )
                                                })}
                                            </Scrollbars>
                                        </Paper>
                                    </div>
                                </React.Fragment>
                                :
                                null
                            }
                        </Box>
                    </div>
                </DialogContent>

                <DialogActions style={{ padding: '0px' }}>
                    <Button id="IM_Cancel_Btn" size="small" startIcon={<CloudUploadIcon />} variant="contained" autoFocus className={classes.yesButton} onClick={() => { UploadMultipleFile(acceptedFiles); }} color="primary" disabled={!acceptedFiles?.length}>
                        Upload
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" startIcon={<CancelIcon />} variant="contained" autoFocus className={classes.noButton} onClick={() => { handleCloseUpload(); }} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen} open={openDialogBox} onClose={handleAlertClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        MESSAGE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <div className={classes.titleHeader}>
                        {message ?
                            <DoneAllIcon style={{ color: 'green', fontSize: '60px' }} />
                            :
                            <ErrorIcon style={{ color: 'red', fontSize: '60px' }} />
                        }
                    </div>
                    <Typography variant="h6" className={classes.textfield1} >
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" size="small" variant="contained" className={classes.noButton} startIcon={<CancelIcon />} onClick={(e) => { setOpenDialogBox(false); GetFilesAsync(); }}>
                        close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen} open={openTimeLine} maxWidth={'md'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleProgressClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        MASS DOCUMENT UPLOAD
                    </Typography>
                </DialogTitleDelete>
                <DialogContent style={{ padding: '0px' }}>
                    <Timeline align="alternate">
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="subtitle1" className={classes.titleColor}>
                                    Verify user
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot variant='default' className={classes.timelineDot}>
                                    <AccountCircleIcon style={{ fontSize: '30px', color: 'white' }} />
                                    {UserVerify && <CircularProgress size={50} className={`${buttonClassname}${classes.fabProgressOne}`} />}
                                </TimelineDot>
                                <TimelineConnector style={{ height: "30px" }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    {UserVerify ?
                                        <Skeleton animation="wave" />
                                        :
                                        (isValid) ?
                                            <div className={classes.massDiv}>
                                                <img alt="verified" src={verified} width="50px" height="50px" />
                                                <Typography variant="h6" component="h1" className={classes.primaryColor}>
                                                    User verified successfully.
                                                </Typography>
                                            </div>
                                            :
                                            <div className={classes.massDiv}>
                                                <img alt="notVerified" src={notVerified} width="50px" height="50px" />
                                                <Typography variant="h6" component="h1" className={classes.errorColor}>
                                                    User not allowed to upload mass files.
                                                </Typography>
                                            </div>
                                    }
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="subtitle1" className={classes.titleColor}>
                                    Gathering the files
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot variant='default' className={classes.timelineDot}>
                                    <FileIcon style={{ fontSize: '30px' }} />
                                    {VerifyFiles && <CircularProgress size={50} className={`${buttonClassname}${classes.fabProgressTwo}`} />}
                                </TimelineDot>
                                <TimelineConnector style={{ height: "30px" }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    {UserVerify || VerifyFiles ?
                                        <Skeleton animation="wave" />
                                        :
                                        (isValid) ?
                                            <div className={classes.massDiv}>
                                                <img alt="fileUploaded" src={fileUploaded} width="50px" height="50px" />
                                                <Typography variant="h6" component="h1" className={classes.primaryColor}>
                                                    Files are ready to upload.
                                                </Typography>
                                            </div>
                                            :
                                            <div className={classes.massDiv}>
                                                <img alt="fileNotUploaded" src={notUploaded} width="50px" height="50px" />
                                                <Typography variant="h6" component="h1" className={classes.errorColor}>
                                                    Files are not ready to upload.
                                                </Typography>
                                            </div>
                                    }
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="subtitle1" className={classes.titleColor}>
                                    Upload files
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot variant='default' className={classes.timelineDot}>
                                    <CloudUploadIcon style={{ fontSize: '30px' }} />
                                    {UploadMassFiles && <CircularProgress size={50} className={`${buttonClassname}${classes.fabProgressThree}`} />}
                                </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    {UserVerify || VerifyFiles || UploadMassFiles ?
                                        <Skeleton animation="wave" />
                                        :
                                        (isValid) ?
                                            <div className={classes.massDiv}>
                                                <img alt="cloudUpload" src={cloudUpload} width="60px" height="60px" />
                                                <Typography variant="h6" component="h1" className={classes.primaryColor}>
                                                    Uploading....
                                                </Typography>
                                            </div>
                                            :
                                            <div className={classes.massDiv}>
                                                <img alt="cloudNotUpload" src={cloudnotUpload} width="60px" height="60px" className={classes.errorColor} />
                                                <Typography variant="h6" component="h1" className={classes.errorColor}>
                                                    Files are not uploaded
                                                </Typography>
                                            </div>
                                    }
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" className={classes.noButton} startIcon={<CancelIcon />} onClick={(e) => { setOpenTimeLine(false); setOpen(false); }} disabled={UserVerify || VerifyFiles || UploadMassFiles}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullScreen={fullScreen}
                open={isTriggerFile}
                aria-labelledby="responsive-dialog-title"
                className={classes.dialogbox}
                PaperProps={{
                    style: {
                        border: "1px solid #0D324D",
                        borderRadius: 15,
                        boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
                    }
                }}
                maxWidth={'sm'}
            >

                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseTrigger}>
                    <Typography variant="h5" gutterBottom className={classes.titleStyle}>
                        MASS DOCUMENT UPLOAD
                    </Typography>
                </DialogTitleHeader>
                <DialogContent style={{ padding: '0px' }}>
                    <div className={classes.titleHeader}>
                        <img src={massUpload} alt="Warning" className={classes.uploadImage} />
                    </div>
                    <Typography variant="h6" gutterBottom className={classes.textfield1}>
                        Are you sure, you want to upload the mass document files ?
                    </Typography>
                </DialogContent>
                <DialogActions style={{ padding: '3px' }}>
                    <Button id="MU_ok_btn" variant="contained" size="small" className={classes.yesButton} startIcon={<CheckCircleIcon />} onClick={(_) => { HandleCheckValidUser() }} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="MU_Cancel_Btn" variant="contained" size="small" autoFocus className={classes.noButton} startIcon={<CancelIcon />} onClick={handleCloseTrigger} color="primary">
                        No
                    </Button>
                </DialogActions >
            </Dialog>

            <Dialog TransitionComponent={Transition} open={isExport} keepMounted
                className={classes.dialogbox}
                PaperProps={{
                    style: {
                        border: "1px solid #0D324D",
                        borderRadius: 15,
                        boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
                    }
                }}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleExportClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleStyle}>
                        EXPORT FILE AUDIT LOG
                    </Typography>
                </DialogTitleHeader>
                <DialogContent style={{ padding: '0px' }}>
                    <div className={classes.titleHeader}>
                        <img src={ExportFileLog} alt="ExportLog" className={classes.uploadImage} />
                    </div>
                    <Typography variant="h6" gutterBottom className={classes.textfield1}>
                        Are you sure, you want to Export file log?
                    </Typography>
                </DialogContent>

                <Box display="flex" alignItems="center" justifyContent="center" className={classes.dialogaction}>
                    <CSVLink
                        data={exportAudit}
                        headers={headers}
                        filename={`${fileName?.substring(0, fileName?.lastIndexOf('.'))}-LOG.csv`}
                        className={classes.ExportTextStyle}
                        target="_blank">
                        <Button id="IM_ok_btn" size="small" startIcon={<CheckCircleIcon />} variant="contained" className={classes.yesButton} onClick={() => { handleExportClose(); }} color="primary" autoFocus>
                            Yes
                        </Button>
                    </CSVLink>
                    <Button id="IM_Cancel_Btn" size="small" startIcon={<CancelIcon />} variant="contained" autoFocus className={classes.noButton} onClick={() => { handleExportClose(); }} color="primary">
                        No
                    </Button>
                </Box>
            </Dialog>

            <Snackbar className="snackBarStyle" open={isExists} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    File Not Exists!
                </Alert>
            </Snackbar>

            <Snackbar className="snackBarStyle" open={isDownload} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    File Downloaded Successfully.
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}

export default FileManager
