import React from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  Theme,
  Toolbar,
  Typography,
  TableRow, TableCell
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import moment from "moment";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import download from "../../../images/files/download01.svg";
import { useGlobalState } from "../../../store/GlobalStore";
import { usePost } from "../../../utils/apiHelper";
import Footer from "../../Footer/Footer";
import LightTooltip from "../../GlobalStyles/LightTooltip";
import { StyledTableCell} from "../../GlobalStyles/TableStyledCell";
import { TableSortLabel } from "@mui/material";
import UnmappedBuilderExpandableRow from "./UnmappedBuilderExpandableRow";
import {IUnmappedFile} from "../../../models/Reports/IUnmappedFile";
import { withStyles } from '@material-ui/core/styles';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridPadding: {
      padding: "8px",
    },
    tableHeaderStyle: {
      background: "#007FFF",
      color: "white",
      fontSize: 12,
      fontWeight: "bold",
    },
    tableHeadRadius: {
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
    },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      border: "1px solid gray",
      minHeight: "35px",
    },
    paper: {
      width: "100%",
    },
    titleColor: {
      color: "blue",
      fontWeight: "bold",
      marginTop: "10px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    footerBoxStyle: {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
    },
    tablebody: {
      maxHeight: 700,
      minHeight: 700,
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 218px)",
        minHeight: "calc( 100vh - 218px)",
      },
    },
    noRecordsStyle: {
      color: "red",
      marginTop: "5px",
      fontSize: 18,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    textdisplay: {
      color: "black",
      fontWeight: "bold",
      textAlign: "center",
    },
    appBar: {
      position: "relative",
      color: "white",
      backgroundColor: "#007fff",
      minHeight: "50px",
    },
    menuButton: {
      marginRight: theme.spacing(1),
      width: 180,
    },
    printTitle: {
      color: "white",
      fontWeight: "bold",
      fontSize: 20,
      textAlign: "center",
    },
    downloadIconStyle: {
      color: "#a1a1a1",
      fontSize: 29,
      marginTop: "5px",
      marginLeft: "12px",
    },
    downloadIconStyle1: {
      color: "#2377E4",
      fontSize: 29,
      marginTop: "5px",
      marginLeft: "12px",
    },
    printStyle: {
      borderRadius: 30,
      marginTop: "5px",
      padding: "2px 2px",
    },
    armlogo: {
      width: 170,
    },
    fabProgressPrint: {
      color: green[500],
      position: "absolute",
      top: 3,
      left: 8,
      zIndex: 1,
    },
    floatTopRight: {
      backgroundColor: "white",
      top: "15px",
      right: "25px",
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
      height: "70px",
    },
    tableContainerPadding: {
      padding: "15px",
      marginTop: "20px",
    },
    summeryBoxShadow: {
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
    },
    boxStyle: {
      gap: "8%",
      padding: "10px",
      float: "right",
      backgroundColor: "#316b97",
      color: "white",
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
      width: "430px",
      height: "220",
      marginTop: "25px",
    },
    sumGap: {
      gap: "8%",
      padding: "10px",
      float: "right",
      backgroundColor: "green",
      color: "white",
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
      width: "450px",
      height: "200px",
      marginTop: "25px",
    },
    sumGap1: {
      gap: "5%",
      marginLeft: "10px",
      borderRadius: 15,
      color: "black",
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
      width: "500px",
      height: "280px",
      marginTop: 15,
      marginRight: 30,
      flexDirection: "column",
    },
    sumGap2: {
      gap: "5%",
      fontWeight: "bold",
      marginTop: "8px",
      fontSize: 27,
      color: "black",
    },
    sumGap3: {
      gap: "5%",
      fontWeight: "bold",
      marginTop: "8px",
      fontSize: 19,
      color: "black",
    },
    sumGap4: {
      gap: "5%",
      marginTop: "8px",
      fontSize: 16,
      color: "green",
    },
    sumGap5: {
      gap: "5%",
      marginTop: "8px",
      fontSize: 18,
      color: "black",
    },
    div: {
      flexGrow: 1,
    },
    tablebodyPrint: {
      overflowX: "hidden",
      marginTop: 5,
    },
    textStyle: {
      textAlign: "right",
      marginTop: "20px",
      fontSize: 16,
    },
    textStyle1: {
      textAlign: "right",
      marginTop: "20px",
      marginRight: 10,
      fontSize: 16,
    },
    totalRecord: {
      gap: "5%",
      marginRight: "10px",
      backgroundColor: "#316b97",
      borderRadius: 15,
      color: "white",
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
      width: "360px",
      height: "130px",
      marginTop: 10,
      flexDirection: "row",
      justifyContent: "center",
    },
    report: {
      overflowX: "hidden",
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(1),
      right: theme.spacing(1),
      marginRight: 20,
    },
    tableCellColor: {
      color: "white",
      fontWeight: "bold",
      fontSize: "12px !important",
      padding: "5px !important",
    },
    tableCellColor1: {
      fontSize: "12px !important",
      padding: "12px !important",
    },
    wrapper: {
      position: "relative",
    },
    backbutton: {
      borderRadius: 20,
      fontSize: 11,
      fontWeight: "bold",
      background: "#007FFF",
      color: "white",
      marginTop: "10px",
      marginRight: "10px",
    },
    bottomFooter: {
      marginTop: "-5px",
      marginBottom: "5px",
    },
  })
);

const LocalStyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: "fileName", numeric: false, disablePadding: false, label: "FILE NAME", sortable: true },
  { id: "created_Date", numeric: false, disablePadding: false, label: "CRETAED DATE", sortable: true },
  { id: "username", numeric: false, disablePadding: false, label: "USER NAME", sortable: false },
  { id: "client_code", numeric: false, disablePadding: false, label: "CLIENT CODE", sortable: false },
  { id: "clT_NAME_1", numeric: false, disablePadding: false, label: "CLIENT NAME", sortable: false },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id} id="GM_TableHeader"
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.TableCellStyle}
          >
            <TableSortLabel
              id="GM_TableSortLable"
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const unmappedBuilder = (props) => {
  const classes = useRowStyles();
  const { state } = useGlobalState();

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState<string>("created_Date");

  const [unmappedFile, setunmappedFile] = useState<IUnmappedFile[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [page, setPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [loading, setLoading] = useState(false);
  let startDate: string = props?.location?.state?.startDate;
  let endDate: string = props?.location?.state?.endDate;
  let folder: number = props?.location?.state?.selectedFolder;

    useEffect(() => {
      setLoading(true);
      (async () => {
        let request = {
          folder_Id: folder,
          start_date: startDate,
          end_date: endDate,
        };
        await usePost<{unmappedCloudFileContracts: IUnmappedFile[], totalRecordCount: number}>("GetUnmappedCloudFile", request).then((result) => {
            setunmappedFile(result?.data?.unmappedCloudFileContracts);
            setTotalCount(result?.data?.totalRecordCount);
          }).finally(() => {
            setLoading(false);
          });
      })()
    }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const headers = [
    { label: "FILE NAME", key: "fileName" },
    { label: "CRETAED DATE", key: "created_Date" },
    { label: "USER NAME", key: "username" },
    { label: "CLIENT CODE", key: "client_code" },
    { label: "CLIENT NAME", key: "clT_NAME_1" },
  ];

  const EnhancedTableToolbar = () => {
    const classes = useRowStyles();

    return (
      <Toolbar className={`${classes.root} ${classes.tableHeadRadius}`}>
        <Grid container spacing={0}>
          <Box p={0} flexShrink={0} width="87%" display="flex">
            <Typography
              variant="h6"
              className={`${classes.titleColor} ${"headertitle"}`}
            >
              {`Unmapped Cloud File Report - ${moment(startDate).format(
                state?.GlobalUtils?.settingValue
              )} to ${moment(endDate).format(
                state?.GlobalUtils?.settingValue
              )}`}
            </Typography>
          </Box>
          <Box display="flex" width="13%">
            <div className={classes.wrapper}>
              <LightTooltip title="Back">
                <Button
                  id="PLC_Back_btn"
                  className={classes.backbutton}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/report"
                  startIcon={
                    <DoubleArrowIcon style={{ transform: "rotate(180deg)" }} />
                  }
                >
                  Back
                </Button>
              </LightTooltip>
            </div>

            <div className={classes.wrapper}>
              <LightTooltip title="Download Reports List">
                <IconButton disabled={!unmappedFile.length} className={classes.printStyle}>
                  <CSVLink
                    data={unmappedFile}
                    headers={headers}
                    filename={`Unmapped Cloud File -${props?.location?.state?.selectedFolder?.label}  ${moment(startDate).format(state?.GlobalUtils?.settingValue)} to ${moment(endDate).format(state?.GlobalUtils?.settingValue)}.csv`}
                    target="_blank">
                    <img src={download} alt="Icon for Download" width="30px"  height="30px" className={!unmappedFile.length ? classes.downloadIconStyle : classes.downloadIconStyle1}/>
                  </CSVLink>
                  {loading && <CircularProgress size={40} className={classes.fabProgressPrint} /> }
                </IconButton>
              </LightTooltip>
            </div>
          </Box>
        </Grid>
      </Toolbar>
    );
  };

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headCells = [
      {
        id: "fileName",
        disablePadding: false,
        label: "FILE NAME",
        sortable: true,
        fontSize: "12px",
      },
      {
        id: "created_Date",
        disablePadding: false,
        label: "CREATED DATE",
        sortable: true,
        fontSize: "12px",
      },
      {
        id: "username",
        disablePadding: false,
        label: "USER NAME",
        sortable: false,
        fontSize: "12px",
      },
      {
        id: "client_code",
        disablePadding: false,
        label: "CLIENT CODE",
        sortable: false,
        fontSize: "12px",
      },
      {
        id: "clT_NAME_1",
        disablePadding: false,
        label: "CLIENT NAME",
        sortable: false,
        fontSize: "12px",
      },
    ];

    return (
      <TableHead>
        <LocalStyledTableRow>
          {headCells.map((headCell) => (
            <StyledTableCell
              size="small"
              key={headCell.id} id="GM_TableHeader"
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.tableHeaderStyle}
              style={{ fontSize: `${headCell.fontSize}` }}
            >
            {headCell.sortable ? (
              <TableSortLabel
              id="GM_TableSortLable"
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>)
            : (
              <span>{headCell.label}</span>
            )}
            </StyledTableCell>
          ))}
        </LocalStyledTableRow>
      </TableHead>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={loading}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container className={classes.gridPadding}>
          <Grid item xs={12} component={Paper}>
            <EnhancedTableToolbar />
            <TableContainer
              component={Paper}
              className={`${
                classes.tablebody
              } ${"scrollbox"} ${"on-scrollbar"}`}
            >
              <Table
                id="unmappedbuilder"
                aria-label="customized table"
                size="small"
                stickyHeader
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(unmappedFile?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map((r) => {
                    return <UnmappedBuilderExpandableRow row={r} />;
                  })}
                </TableBody>
              </Table>
              {!loading && !unmappedFile.length ? (
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.noRecordsStyle}
                >
                  No records to display...
                </Typography>
              ) : null}
            </TableContainer>
            <Grid container spacing={0} className={classes.footerBoxStyle}>
              <Box m={0} width="65%">
                <div className="footer">
                  <Footer />
                </div>
              </Box>
              <Box m={0} width="35%">
                <div className="pagination">
                  <TablePagination
                    id="unmapped_TablePagination"
                    rowsPerPageOptions={[15, 25, 50]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default unmappedBuilder;
