import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { IUnmappedFile } from '../../../models/Reports/IUnmappedFile';
import { useGlobalState } from '../../../store/GlobalStore';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 12,
        },
        rootHidden: {
            display: "none"
        },
        collapsePadding: {
            padding: '8px'
        },
        tablefont: {
            fontSize: 12
        }
    })
);

const UnmappedBuilderExpandableRow: React.FC<{ row: IUnmappedFile }> = (props) => {
    const classes = useRowStyles();
    const { row } = props;
    const { state } = useGlobalState();

    return (
        <React.Fragment>
            <TableRow key={row?.fileName}>
                <TableCell className={classes.tablefont}>{row?.fileName}</TableCell>
                <TableCell className={classes.tablefont}>{row?.created_Date !== null ? moment(row?.created_Date).format(state.GlobalUtils?.settingValue) : row?.created_Date}</TableCell>
                <TableCell className={classes.tablefont}>{row?.username}</TableCell>
                <TableCell className={classes.tablefont}>{row?.client_code}</TableCell>
                <TableCell className={classes.tablefont}>{row?.clT_NAME_1}</TableCell>
            </TableRow>
        </React.Fragment >
    );
}

export default UnmappedBuilderExpandableRow;
