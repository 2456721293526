
import { green } from '@material-ui/core/colors';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        girdPadding: {
            padding: '10px'
        },
        sidebar: {
            width: "16rem",
            padding: "0.5rem",
            backgroundColor: 'white',
            transition: "all 0.5s ease",
            display: 'block',
            whiteSpace: 'nowrap',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            borderRight: '1px solid black'
        },
        sidebarCollapsed: {
            borderTopColor: 'white',
            width: "2.3rem",
            padding: "0.5rem",
            backgroundColor: 'white',
            transition: "all 0.5s ease",
            display: 'block',
            whiteSpace: 'nowrap',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            borderLeft: '1px solid black'
        },
        subSidebar: {
            width: "14rem",
            padding: "0.5rem",
            backgroundColor: 'white',
            transition: "all 0.5s ease",
            display: 'block',
            whiteSpace: 'nowrap',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            borderRight: '1px solid black'
        },
        subSidebarCollapsed: {
            borderTopColor: 'white',
            width: "2.3rem",
            padding: "0.5rem",
            backgroundColor: 'white',
            transition: "all 0.5s ease",
            display: 'block',
            whiteSpace: 'nowrap',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            borderRight: '1px solid black'
        },
        ftpsubSidebar: {
            width: "14rem",
            padding: "0.5rem",
            backgroundColor: 'white',
            transition: "all 0.5s ease",
            display: 'block',
            whiteSpace: 'nowrap',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            borderRight: '1px solid black'
        },
        ftpsubSidebarCollapsed: {
            borderTopColor: 'white',
            width: "2.3rem",
            padding: "0.5rem",
            backgroundColor: 'white',
            transition: "all 0.7s ease",
            display: 'block',
            whiteSpace: 'nowrap',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            borderRight: '1px solid black'
        },
        sidebarHeader: {
            display: 'flex'
        },
        sidebarItem: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: '5px',
            transition: '0.2s',
            cursor: 'pointer',
            padding: '5px',
            height: '2cm',
        },
        textpropertyOut: {
            color: 'black',
            paddingLeft: '7px',
            cursor: 'pointer',
            fontSize: '12px',
            transitionDelay: '0.5s'
        },
        textProperty: {
            visibility: 'hidden',
            transitionDelay: '0.5s'
        },
        iconPosition: {
            marginLeft: '-2px'
        },
        titleColor: {
            fontWeight: 'bold',
            fontSize: '22px',
            visibility: 'hidden'
        },
        CheckBoxStyle: {
            color: "#00e676",
        },
        fileNameStyle: {
            minWidth: '30px'
        },
        listIconSize: {
            marginTop: '5px',
            fontSize: '18px',
            marginLeft: '5px'
        },
        fileText: {
            fontSize: '11px',
        },
        folderTitleExtended: {
            fontSize: '13px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            fontWeight: 'bold',
            marginLeft: '5px'
        },
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
        searchBox: {
            borderRadius: "30px",
            border: '2px black solid',
            width: '95%',
            height: '32px',
            textIndent: '10px',
            paddingLeft: '0px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        },
        outlinedInput: {
            fontSize: 14,
            color: "black",
            marginTop: '-13px',
            '&$focused $notchedOutline': {
                color: 'white',
                border: '0px'
            },
            '&.MuiOutlinedInput-adornedEnd': {
                paddingRight: '0px'
            }
        },
        notchedOutline: {
            color: 'white',
            border: '0px'
        },
        folderTitle: {
            fontSize: '12px',
            flex: '1 1 100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            fontWeight: 'bold'
        },
        folderSubTitle: {
            flex: '1 1 100%',
            display: 'flex',
            fontSize: '12px',
            marginLeft: '5px',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
        },
        iconHeight: {
            marginTop: '2px'
        },
        PrimaryboxGap: {
            gap: '2%'
        },
        SecondaryBoxGap: {
            marginTop: '0px',
        },
        footer: {
            marginTop: '15px',
            float: 'right',
        },
        MainFolderTitle: {
            flex: '1 1 100%',
            fontSize: '13px',
            fontWeight: 'bold',
            color: 'black',
            marginTop: '5px',
            marginLeft: '5px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
        },
        MainFolderTitleHidden: {
            visibility: 'hidden'
        },
        searchboxHide: {
            visibility: 'hidden'
        },
        subiconPosition: {
            marginLeft: '-15px'
        },
        borderStyle: {
            borderBottom: '1px solid black'
        },
        ToolbarStyle: {
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            backgroundColor: 'white',
            borderLeft: '1px solid black',
            minHeight: '45px',
            padding: '0px 5px 0px 5px'
        },
        tablebody: {
            overflowX: 'hidden',
            maxHeight: 580,
            minHeight: 580,
            borderBottom: '1px solid gray',
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 227px)',
                minHeight: 'calc( 100vh - 227px)',
            }
        },
        AuditTableStyle: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
        tableCellStyle: {
            minHeight: '50px'
        },
        TableCellPadding: {
            padding: '10px'
        },
        breadCrumsStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;'
        },
        searchBox2: {
            borderRadius: "30px",
            border: '2px black solid',
            width: '100%',
            height: '27px',
            textIndent: '7px',
            paddingLeft: '0px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        },
        ftpoutlinedInput: {
            fontSize: 12,
            color: "black",
            marginTop: '-13px',
            '&$focused $notchedOutline': {
                color: 'white',
                border: '0px'
            },
            '&.MuiOutlinedInput-adornedEnd': {
                paddingRight: '0px'
            }
        },
        ftpnotchedOutline: {
            color: 'white',
            border: '0px'
        },
        ftpDiv: {
            display: 'flex',
            borderTop: '1px solid black'
        },
        noRecordsColor: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '95px',
        },
        fabProgressPrint: {
            color: green[500],
            position: 'absolute',
            top: -6,
            left: -5,
            zIndex: 1
        },
        snackbar: {
            color: 'black',
            marginTop: '-0.5cm',
        },
        snackbarfont: {
            fontSize: 16,
        },
        rowhead: {
            backgroundColor: '#d7d9d8',
            padding: '0px 2px 0px 2px',
            fontSize: '11px'
        },
        rowpadding: {
            padding: '0px 2px 0px 2px',
            fontSize: '12px'
        },
        actionIcon: {
            padding: '4px',
        },
        noRecord: {
            color: 'red',
            marginTop: '10px',
            marginLeft: '10px',
            fontSize: '17px'
        },
        thPadding: {
            padding: '0px 0px 0px 0px',
            backgroundColor: '#d7d9d8',
            marginLeft: '3px'
            // width:
        },
        divFiles: {
            width: '100%'
        },
        breadCrumsDiv: {
            display: 'flex',
            height: '40px'
        },
        TextHighlight: {
            backgroundColor: "yellow",
            fontSize: "11px",
            fontWeight: 'bold',
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            [theme.breakpoints.up('lg')]: {
                width: '200px',
            },
            [theme.breakpoints.down('lg')]: {
                width: '200px',
            },
        },
        TextHighlight1: {
            backgroundColor: "yellow",
            fontSize: "11px",
            fontWeight: 'bold',
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            [theme.breakpoints.up('lg')]: {
                width: '550px',
            },
            [theme.breakpoints.down('lg')]: {
                width: '550px',
            },
        },
        searchTextHighlight: {
            backgroundColor: "yellow",
            fontSize: "11px",
            fontWeight: 'bold',
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        NormalText: {
            color: 'black',
            fontSize: "11px",
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            [theme.breakpoints.up('lg')]: {
                width: '200px',
            },
            [theme.breakpoints.down('lg')]: {
                width: '200px',
            },
        },
        NormalText1: {
            color: 'black',
            fontSize: "11px",
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            [theme.breakpoints.up('lg')]: {
                width: '550px',
            },
            [theme.breakpoints.down('lg')]: {
                width: '550px',
            },
        },
        subFolderNormalText: {
            color: 'black',
            fontSize: "11px",
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        subFolderCountText: {
            color: 'blue !important',
            fontSize: "11px",
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        listpadding: {
            padding: '2px 0px'
        },
        listpadding1: {
            padding: '5px 0px'
        },
        FtpfolderStyle: {
            height: '30px',
            minWidth: '25px'
        },
        spinnerStyle: {
            display: 'flex',
            marginTop: '200px',
            marginBottom: '5px'
        },
        loadingDiv: {
            backgroundColor: 'white',
            width: "15%",
            display: 'flex'
        },
        IconStyle: {
            color: '#215176',
            transform: 'rotate(-90deg)',
            fontSize: '16px'
        },
        iconSpacing: {
            marginRight: '15px'
        },
        BoxGapOne: {
            gap: '20px'
        },
        yesButton: {
            background: "green",
            color: 'white',
            fontSize: '12px',
            borderRadius: 20,
            marginBottom: '5px',
            '&:hover': {
                background: "green",
            }
        },
        ExportTextStyle: {
            textDecoration: 'none'
        },
        noButton: {
            background: "red",
            borderRadius: 20,
            color: 'white',
            display: 'flex',
            fontSize: '12px',
            marginBottom: '5px',
            marginRight: '10px',
            '&:hover': {
                background: "red",
            }
        },
        dialogbox: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            height: '100%'
        },
        titleHeader: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px'
        },
        titleStyle: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white',
            fontSize: 18
        },
        Image: {
            height: 100,
            width: 100,
        },
        image2: {
            height: 100,
            width: 100,
        },
        textfield1: {
            textAlign: 'center',
            color: 'black'
        },
        dialogaction: {
            gap: '3%',
            marginTop: '10px'
        },
        uploadDialogAction: {
            gap: '3%',
            marginTop: '10px',
        },
        noRecordsStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        inputArchive: {
            color: "white"
        },
        input: {
            color: "black"
        },
        fileChip: {
            display: 'flex',
            borderRadius: '5px',
            marginTop: '5px',
            justifyContent: 'left',
            flexWrap: 'wrap',
            border: '1px solid gray',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        invalidFileChip: {
            display: 'flex',
            borderRadius: '5px',
            marginTop: '5px',
            justifyContent: 'left',
            flexWrap: 'wrap',
            border: '1px solid gray',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        button: {
            background: "#007FFF",
            color: "white",
            marginTop: "8px",
            borderRadius: "20px"
        },
        yesbutton: {
            backgroundColor: 'green',
            borderRadius: '20px',
            color: 'white',
            fontSize: 12,
            '&:hover': {
                backgroundColor: 'green',
                color: 'white',
            }
        },
        nobutton: {
            background: "red",
            borderRadius: "20px",
            color: 'white',
            fontSize: 12,
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            }
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white',
            fontSize: 18
        },
        IconButtonripple: {
            '&:hover': {
                backgroundColor: 'transparent',
            }
        },
        image: {
            width: '70px',
            height: '70px',
            textAlign: 'center'
        },
        card1: {
            display: 'block',
            width: '5.5cm',
            height: '4cm',
            marginLeft: '30px',
            marginTop: '0.5cm',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            textDecoration: 'none',
            '&:hover': {
                color: 'black',
                backgroundColor: 'white',
                borderRadius: '10px',
                transform: 'scale(1.1)',
                border: '2px blue solid',
                cursor: 'pointer',
                boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
            },
        },
        text: {
            color: "black",
            textAlign: "center",
            fontSize: '16px',
            fontWeight: 'bold',
        },

        yesButtonStyle: {
            background: "red",
            color: 'white',
            borderRadius: 20,
            right: '15px',
            '&:hover': {
                background: "red",
            }
        },
        MessageStyle: {
            marginTop: "5px",
            color: "black",
            fontSize: 17
        },
        loadingMessage: {
            marginTop: '10px'
        },
        paper: {
            padding: '6px 16px',
            borderTop: '4px solid #49cc90',
        },
        fabProgressOne: {
            color: 'green',
            position: 'absolute',
            top: 7,
            left: 440,
            zIndex: 1
        },
        buttonSuccess: {
            backgroundColor: 'green',
            '&:hover': {
                backgroundColor: 'green',
            },
        },
        fabProgressTwo: {
            color: 'green',
            position: 'absolute',
            top: 7,
            left: 440,
            zIndex: 1
        },
        fabProgressThree: {
            color: 'green',
            position: 'absolute',
            top: 7,
            left: 440,
            zIndex: 1
        },
        errorColor: {
            color: 'red',
            fontSize: '17px'
        },
        primaryColor: {
            color: 'black',
            fontSize: '17px'
        },
        titleColorStyle: {
            color: 'black'
        },
        browseFileDiv: {
            display: 'flex',
            marginTop: '5px'
        },
        browseBoxStyle: {
            border: '1px solid gray',
            borderRadius: '10px',
            padding: '10px'
        },
        accFiles: {
            backgroundColor: 'rgba(73,204,144,.1)',
            border: '1px solid #49cc90',
            color: 'black',
            fontWeight: 'bold',
            margin: theme.spacing(0.5)
        },
        regFiles: {
            backgroundColor: 'rgba(249,62,62,.1)',
            border: '1px solid #f93e3e',
            color: 'black',
            fontWeight: 'bold',
            margin: theme.spacing(0.5)
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing(7),
            right: theme.spacing(2),
        },
        uploadButton: {
            paddingLeft: '5px',
            fontSize: '12px'
        },
        TextColorGreen: {
            color: 'green',
            fontWeight: "bold"
        },
        TextColorRed: {
            color: '#fb6767',
            fontWeight: "bold"
        },
        rejBoxPadding: {
            marginTop: '10px'
        },
        root2: {
            border: "2px dashed #215176",
            borderRadius: 5,
            minWidth: '930px',
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
            alignItems: 'center',
            marginTop: '4px',
            flexDirection: 'column',
            marginLeft: '10px'
        },
        textStyle: {
            fontSize: 12,
            marginTop: '0px'
        },
        ulstyle: {
            listStyleType: "none",
            margin: '10px',
        },
        fileUploadButton: {
            borderRadius: 20,
            fontSize: 12,
            marginRight: '1cm',
            background: "#007FFF",
            color: "white",
            '&:hover': {
                background: "#007FFF",
            }
        },
        disableSelect: {
            pointerEvents: 'none'
        },
        massuploadButton: {
            borderRadius: '20px',
            fontSize: '12px',
            marginTop: '3px',
            padding: '3px 10px'
        },
        breadCrumsDivStyle: {
            width: '90%',
            backgroundColor: 'white'
        },
        debtorfileDiv: {
            width: '10%',
            backgroundColor: 'white'
        },
        titleHeaderOverride: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px'
        },
        confirmtextStyle: {
            textAlign: 'center',
            color: 'black',
            fontSize: 18
        },
        uploadImage: {
            width: '70px',
            height: '70px',
            textAlign: 'center',
            marginTop: '5px'
        },
        massDiv: {
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        },
        timelineDot: {
            background: '#1976d2',
            border: '4px solid white'
        },
        paperDropdown: {
            border: "2px solid black",
            fontSize: 12
        },
        fontDropdown: {
            fontSize: 12,
            textOverflow: 'ellipsis'
        },
        folderColor: {
            borderRadius: '6px',
            height: '27px',
            minWidth: '25px'
        },
        moveFile: {
            fontSize: '18px !important',
            color: '#219ebc'
        },
        GreenColor: {
            color: 'green'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        errorStyle: {
            display: 'block',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            [theme.breakpoints.up('lg')]: {
                width: '250px',
            },
            [theme.breakpoints.down('lg')]: {
                width: '145px',
            },
        }
    })
);

export default useStyles